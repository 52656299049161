import React from "react";

export default (props) => (
	<button
		type={props.submit ? "submit" : "button"}
		className={`btn btn-block btn-${props.small ? "xs" : "lg"} btn-outline-${props.type}`}
		onClick={props.event}
		disabled={props.disabled}
	>
		{props.text}
	</button>
);

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
