import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Aplicando o tema animado
am4core.useTheme(am4themes_animated);

const LineChart = ({ seriesData }) => {
	useEffect(() => {
		const chart = am4core.create("chartdiv", am4charts.XYChart);
		chart.background.fill = "#E9E9FF";
		chart.background.opacity = 1;
		chart.plotContainer.contentHeight = chart.plotContainer.contentHeight + 50;

		const title = chart.titles.create();
		title.text = "Gráfico de iluminação por horário";
		title.fontWeight = "bold";
		title.fontSize = "1.2rem";
		title.fill = am4core.color("#4968a3");
		title.marginTop = 20;
		title.marginBottom = 20;

		// Configurando o eixo X
		const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "horarios";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 50;

		// Configurando o eixo Y
		const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.numberFormatter = new am4core.NumberFormatter();
		valueAxis.numberFormatter.numberFormat = "#'%'";
		valueAxis.min = 0;
		valueAxis.max = 100;
		valueAxis.extraMax = 0.1; // Adiciona 10% de espaço extra acima do valor máximo

		// Add an adapter to the axis labels
		valueAxis.renderer.labels.template.adapter.add("text", function (text, target) {
			if (parseFloat(text) > 100) {
				return "";
			}
			return text;
		});

		// Função para gerar intervalos de 30 minutos durante um dia
		const generateTimeSlots = () => {
			const horarios = [];
			for (let h = 0; h < 24; h++) {
				for (let m = 0; m < 60; m += 30) {
					const hour = String(h).padStart(2, "0");
					const minute = String(m).padStart(2, "0");
					horarios.push(hour + ":" + minute);
				}
			}
			return horarios;
		};

		// Gerando horários para o eixo X
		const horarios = generateTimeSlots();

		// Criando dados fictícios para 4 séries
		const chartData = horarios.map((horario, indice) => ({
			horarios: horario,
		}));

		// Função para adicionar série
		const createSeries = (field, name, color) => {
			const series = chart.series.push(new am4charts.LineSeries());
			series.dataFields.valueY = field;
			series.dataFields.categoryX = "horarios";
			series.name = name;
			series.tooltipText = "{name}: [bold]{valueY}% às {categoryX}h[/]";
			series.stroke = color + "cc";
			series.fill = color;
			series.strokeWidth = 2;
			series.minBulletDistance = 15;
			series.bullets.push(new am4charts.CircleBullet());
		};

		// Criando uma série para cada conjunto de dados
		for (const series of seriesData) {
			createSeries(series.field, series.name, series.color);
			chartData.forEach((item, indice, lista) => {
				lista[indice] = { ...item, [series.field]: Math.round((series.data[indice].valor * 100) / 1023) };
			});
		}

		//adiciona os dados no grafico
		chart.data = chartData;

		// Adicionando cursor do mouse
		chart.cursor = new am4charts.XYCursor();

		// Adicionando uma legenda interativa
		chart.legend = new am4charts.Legend();
		chart.legend.useDefaultMarker = true;

		// Permitir selecionar/desselecionar séries na legenda
		chart.legend.itemContainers.template.events.on("hit", (ev) => {
			let series = ev.target.dataItem.dataContext;
			if (series.isHidden) {
				series.show();
			} else {
				series.hide();
			}
		});

		// Atualizar a legenda quando a série for ocultada/exibida
		chart.series.each(function (series) {
			series.events.on("hidden", function (event) {
				chart.legend.markers.each(function (marker) {
					if (marker.dataItem.dataContext === event.target) {
						marker.parent.isActive = true;
					}
				});
			});
			series.events.on("shown", function (event) {
				chart.legend.markers.each(function (marker) {
					if (marker.dataItem.dataContext === event.target) {
						marker.parent.isActive = false;
					}
				});
			});
		});
		return () => {
			chart.dispose();
		};
	}, [seriesData]);

	return <div id="chartdiv" style={{ width: "100%", minWidth: 1150, height: "500px", borderRadius: "0.8rem", overflow: "hidden" }}></div>;
};

export default LineChart;
