import "./auth.css";
import React, { Component } from "react";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Link,
	Container,
	Snackbar,
	Dialog,
	DialogContentText,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import InputMask from "react-input-mask";
import { ThemeProvider } from "@material-ui/styles";

import getTheme from "../common/template/getTheme";
import getStyles from "../common/template/getStyles";

import imagemLogo from "../assets/images/logo.png";
// import imagemFrase from "../assets/images/login_frase.png";
import backgroundVideo from "../assets/videos/background_login.mp4";

import { login, signup, recuperarSenha, validarCodigo, alterarSenha, initForm } from "./authActions";

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = { modoTela: "login" };
	}

	changeMode(modo) {
		this.setState({ modoTela: modo });
	}

	onSubmit(values) {
		const { login, signup, recuperarSenha, validarCodigo } = this.props;
		if (this.state.modoTela == "login") {
			login(values);
		} else if (this.state.modoTela == "cadastro") {
			signup(values);
		} else if (this.state.modoTela == "recuperarSenha") {
			recuperarSenha(values, (retorno) =>
				this.setState({
					...this.state,
					usuarioValidacao: retorno,
					modoTela: "validarCodigo",
				})
			);
		} else if (this.state.modoTela == "validarCodigo") {
			validarCodigo({ ...values, codigoValido: this.state.usuarioValidacao.codigo }, (retorno) =>
				this.setState({ ...this.state, modoTela: "alterarSenha" })
			);
		} else if (this.state.modoTela == "alterarSenha") {
			recuperarSenha({ ...values }, (retorno) => this.setState({ modoTela: "login" }));
		}
	}

	render() {
		const classes = getStyles();

		const { handleSubmit } = this.props;

		return (
			<ThemeProvider theme={getTheme()} style={{ backgroundColor: "#f00" }}>
				{this.state.dialogTermosUso ? (
					<Dialog
						open={this.state.dialogTermosUso}
						onClose={() => this.setState({ ...this.state, dialogTermosUso: false })}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{"Termos de Uso e Política de Privacidade"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								As informações contidas na sua conta pessoal neste sistema são de sua responsabilidade. A Doled não tem acesso a sua senha. Os dados
								fornecidos ao criar sua conta são de inteira responsabilidade sua. Caso um dos itens abaixo seja violado, a Doled reserva o direito de
								suspender sua conta - permanentemente ou não - com ou sem aviso prévio com intuito de preservar os direitos humanos e leis aplicáveis
								em território Brasileiro.
								<br />
								<br />
								<b>É extremamente proibido:</b>
								<br />
								- A cópia de qualquer item da Dashboard da Doled, tanto para fins pessoais quanto comerciais;
								<br />
								- O uso da marca Doled em divulgações de vídeos e fotos sem devida autorização;
								<br />
								- O uso da dashboard Doled em divulgações de vídeos e fotos sem devida autorização;
								<br />
								<br />
								<b>Da autorização:</b>
								<br />
								- Poderá ser fornecida a punho ou de maneira digital via email, ficando a critério da Doled.
								<br />
								<br />
								<b>Fotos e imagens:</b>
								<br />
								- Fotos relacionadas com pornografia de qualquer gênero, racismo de qualquer genero ou qualquer item que possa ferir os direitos
								humanos;
								<br />
								<br />
								<b>Em caso de perda:</b>
								<br />
								- Em caso de perda de email e consequentemente perda da senha de acesso da Dashboard Doled, a conta poderá ser suspensa. A suspensão
								da conta só poderá ser feita pelo proprietário da mesma, comprovada com nota fiscal que deverá ser enviada juntamente com autorização
								de suspensão assinada a punho pelo proprietário, podendo ser enviada por email staff@doled.com.br Somente após isso outra conta será
								gerada em um novo email.
								<br />
								<br />
								<b>Sobre estes termos:</b>
								<br />
								- A empresa poderá modificar os Termos e Condições a qualquer momento, sem aviso prévio.
								<br />
								<br />
								<b>Conexão com servidor:</b>
								<br />
								- A Doled reserva seu direito de a qualquer momento interromper a conexão do servidor para todos usuários sem aviso prévio, caso
								necessário, visando a proteção contra ataques, manutenções e outros fins justificados pela empresa.
								<br />
								- A empresa contratada para hospedar o servidor garante até 99% do tempo anual sem quedas.
								<br />
								<br />
								<b>Leis aplicáveis:</b>
								<br />
								- É vedado o uso do sistema para fins de cultivo de substâncias ilícitas, conforme a vigência das leis brasileiras. O cultivo de
								qualquer substância ilícita irá acarretar na suspensão da conta. O uso do produto é de exclusiva responsabilidade do comprador.
								<br />
								- A Doled possui acesso aos dados da sua conta, nome, CPF e endereço de email;
								<br />- A Doled possui acesso remoto a todos os dispositivos online e reserva-se ao acesso quando necessário, sem aviso prévio;
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({ ...this.state, dialogTermosUso: false })} color="primary" autoFocus>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
				) : null}

				<React.Fragment>
					<CssBaseline />
					<video
						style={{
							height: "100%",
							width: "100%",
							top: 0,
							left: 0,
							objectFit: "cover",
							position: "absolute",
							zIndex: 0,
						}}
						autoPlay
						loop
						muted
					>
						<source src={backgroundVideo} type="video/mp4" />
					</video>
					<Container
						component="main"
						maxWidth="xs"
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							height: "100vh",
						}}
					>
						<CssBaseline />
						<div
							className={classes.paper}
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								background: "#fff",
								opacity: 0.96,
								borderRadius: 7,
								paddingTop: 50,
								paddingLeft: 20,
								paddingRight: 20,
								paddingBottom: 50,
								maxHeight: "fit-content",
								zIndex: 9999,
							}}
						>
							<img
								alt="Aquarium LED Quality"
								src={imagemLogo}
								style={{
									width: 100,
									marginBottom: 10,
								}}
							/>
							<h4>
								<strong>Aquarium LED Quality</strong>
							</h4>

							{this.state.modoTela == "login" ? (
								<form className={classes.form} noValidate onSubmit={handleSubmit((v) => this.onSubmit(v))}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="login"
										label="Email"
										name="login"
										autoFocus
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												login: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="senha"
										label="Senha"
										type="password"
										id="senha"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												senha: e.target.value,
											});
										}}
									/>

									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
										}}
									>
										<FormControlLabel
											control={
												<Checkbox
													variant="outlined"
													margin="normal"
													required
													fullWidth
													name="manter_logado"
													id="manter_logado"
													onChange={(e) => {
														this.props.initForm({
															...this.props.formularioValues,
															manter_logado: this.props.formularioValues && this.props.formularioValues.manter_logado ? false : true,
														});
													}}
													inputProps={{ "aria-label": "primary checkbox" }}
												/>
											}
											label="Mantenha-me logado"
										/>

										<Link href="#" style={{ fontSize: 16, marginTop: 10 }} onClick={() => this.changeMode("recuperarSenha")}>
											Esqueci minha Senha
										</Link>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											paddingTop: 30,
										}}
									>
										<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} style={{ marginRight: 5 }}>
											Entrar
										</Button>

										<Button fullWidth variant="outlined" color="secondary" style={{ marginLeft: 5 }} onClick={() => this.changeMode("cadastro")}>
											Cadastrar
										</Button>
									</div>
								</form>
							) : this.state.modoTela == "cadastro" ? (
								<form className={classes.form} noValidate onSubmit={handleSubmit((v) => this.onSubmit(v))}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="login"
										label="Nome"
										name="nome"
										autoFocus
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												nome: e.target.value,
											});
										}}
									/>

									<InputMask
										mask="999.999.999-99"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												cpf: (e.target.value || "").split(".").join("").split("-").join(""),
											});
										}}
									>
										{(inputProps) => (
											<TextField {...inputProps} variant="outlined" margin="normal" required fullWidth id="CPF" label="CPF" name="cpf" />
										)}
									</InputMask>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email"
										name="email"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												email: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="senha"
										label="Senha"
										type="password"
										id="senha"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												senha: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="senha_confirmacao"
										label="Confirmar Senha"
										type="password"
										id="senha_confirmacao"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												senha_confirmacao: e.target.value,
											});
										}}
									/>

									<FormControlLabel
										style={{ marginTop: 4 }}
										control={
											<Checkbox
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="termo_uso"
												id="termo_uso"
												onChange={(e) => {
													this.props.initForm({
														...this.props.formularioValues,
														termo_uso: this.props.formularioValues && this.props.formularioValues.termo_uso ? false : true,
													});
												}}
												inputProps={{ "aria-label": "primary checkbox" }}
											/>
										}
										label="Li e concordo com os"
									/>

									<Link href="#" style={{ fontSize: 16, marginLeft: -10 }} onClick={() => this.setState({ ...this.state, dialogTermosUso: true })}>
										termos de uso
									</Link>

									<FormControlLabel
										control={
											<Checkbox
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="manter_logado"
												id="manter_logado"
												onChange={(e) => {
													this.props.initForm({
														...this.props.formularioValues,
														manter_logado: this.props.formularioValues && this.props.formularioValues.manter_logado ? false : true,
													});
												}}
												inputProps={{ "aria-label": "primary checkbox" }}
											/>
										}
										label="Mantenha-me logado"
									/>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											paddingTop: 30,
										}}
									>
										<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
											Cadastrar
										</Button>
									</div>
								</form>
							) : this.state.modoTela == "recuperarSenha" ? (
								<form className={classes.form} style={{ width: "100%" }} noValidate onSubmit={handleSubmit((v) => this.onSubmit(v))}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email"
										name="email"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												email: e.target.value,
											});
										}}
									/>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											paddingTop: 30,
										}}
									>
										<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
											Recuperar
										</Button>
									</div>
								</form>
							) : this.state.modoTela == "validarCodigo" ? (
								<form className={classes.form} style={{ width: "100%" }} noValidate onSubmit={handleSubmit((v) => this.onSubmit(v))}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email"
										name="email"
										disabled
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												email: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="codigo"
										label="Código enviado para seu email"
										id="codigo"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												codigo: e.target.value,
											});
										}}
									/>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											paddingTop: 30,
										}}
									>
										<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
											Validar
										</Button>
									</div>
								</form>
							) : this.state.modoTela == "alterarSenha" ? (
								<form className={classes.form} style={{ width: "100%" }} noValidate onSubmit={handleSubmit((v) => this.onSubmit(v))}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email"
										name="email"
										disabled
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												email: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="codigo"
										label="Código enviado para seu email"
										id="codigo"
										disabled
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												codigo: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="senha"
										label="Senha"
										type="password"
										id="senha"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												senha: e.target.value,
											});
										}}
									/>

									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="senha_confirmacao"
										label="Confirmar Senha"
										type="password"
										id="senha"
										onChange={(e) => {
											this.props.initForm({
												...this.props.formularioValues,
												senha_confirmacao: e.target.value,
											});
										}}
									/>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											paddingTop: 30,
										}}
									>
										<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
											Alterar Senha
										</Button>
									</div>
								</form>
							) : null}
						</div>
						<Snackbar open={this.props.message.open}>
							<MuiAlert severity={this.props.message.type} variant="filled">
								{this.props.message.text}
							</MuiAlert>
						</Snackbar>
					</Container>
				</React.Fragment>
			</ThemeProvider>
		);
	}
}

Auth = reduxForm({ form: "authForm" })(Auth);
const mapStateToProps = (state) => ({
	message: state.auth.message,
	formularioValues: getFormValues("authForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ login, signup, validarCodigo, recuperarSenha, alterarSenha, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
