import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CssBaseline, Grid, FormLabel } from "@material-ui/core";

import {
	WatchLater as WatchLaterIcon,
	CalendarToday as CalendarTodayIcon,
	WbSunny as WbSunnyIcon,
	WbIncandescent as WbIncandescentIcon,
	SignalWifi0Bar as SemInternetIcon,
	SignalWifi1Bar as SinalRuimInternetIcon,
	SignalWifi3Bar as SinalBomInternetIcon,
	SignalWifi4Bar as SinalExcelenteInternetIcon,
	AddPhotoAlternate as AddPhotoAlternateIcon,
	RemoveCircleOutline as RemoveCircleOutlineIcon,
	NightsStay as NightsStayIcon,
	Image as ImageIcon,
} from "@material-ui/icons";
import { DeviceThermostat, FlashOn } from "@mui/icons-material";

import DashboardCard from "../common/components/dashboardCard";
import RadialChart from "../common/components/radialChart";

import { setModoTela, getDados } from "./dashboardActions";
import { getLista as getListaDispositivo, alterarImagemDispositivo } from "../dispositivo/dispositivoActions";

class Dashboard extends Component {
	state = {
		corBotaoAddImagem: "#ffffff",
		corBotaoRemoveImagem: "#ffffff",
	};

	async componentDidMount() {
		await this.props.getListaDispositivo(this.props.sessao.id || 0);
		this.props.getDados();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
			prevProps.dispositivoSelecionado !== null
		)
			this.props.getDados();

		const fileSelectorImagem = document.createElement("input");
		fileSelectorImagem.setAttribute("type", "file");
		fileSelectorImagem.addEventListener("change", (data) => {
			let reader = new FileReader();
			let file = data.target.files[0];
			if (file) {
				if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
					alert("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
					return;
				}

				reader.onloadend = () => {
					this.setState({ ...this.state, imagem_aquario: reader.result });
				};
				reader.readAsDataURL(file);
			}
		});

		this.fileSelectorImagem = fileSelectorImagem;
		if (prevState.imagem_aquario !== this.state.imagem_aquario) {
			this.props.alterarImagemDispositivo(this.state.imagem_aquario || null);
		}
	}
	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100,
			},
		};
	}

	render() {
		const style = this.getLocalStyles();
		return (
			<React.Fragment>
				<CssBaseline />

				{
					<Grid container spacing={1} style={style.root}>
						<DashboardCard
							xs={12}
							xl={12}
							sm={12}
							md={12}
							lg={12}
							color="green"
							icon={
								!this.props.registro.wifi ? (
									<SemInternetIcon />
								) : this.props.registro.wifi == "Ruim" ? (
									<SinalRuimInternetIcon />
								) : this.props.registro.wifi == "Bom" ? (
									<SinalBomInternetIcon />
								) : (
									<SinalExcelenteInternetIcon />
								)
							}
							title="SINAL WI-FI"
							subtitle={this.props.registro.wifi || "Dispositivo inacessível"}
						/>
						<DashboardCard
							xs={12}
							sm={6}
							md={4}
							lg={4}
							color="blue"
							icon={<WatchLaterIcon />}
							title="TEMPO ONLINE"
							subtitle={this.props.registro.tempo}
						/>
						<DashboardCard
							xs={12}
							sm={6}
							md={4}
							lg={4}
							color="red"
							icon={<CalendarTodayIcon />}
							title="DATA E HORA"
							subtitle={this.props.registro.dataHora}
						/>

						<DashboardCard
							xs={12}
							sm={6}
							md={4}
							lg={4}
							color="purple"
							icon={<NightsStayIcon />}
							title={`FASE DA LUA`}
							subtitle={this.props.registro.iluminacaoLua}
						/>
						<DashboardCard
							xs={12}
							sm={6}
							md={4}
							lg={4}
							color="lightblue"
							icon={<WbSunnyIcon />}
							title="NUVEM"
							subtitle={
								<>
									<span style={{ width: "100%" }}>{this.props.registro.tempoProximaNuvem}</span>
									<span style={{ width: "100%" }}>{this.props.registro.horarioProximaNuvem}</span>
								</>
							}
						/>
						<DashboardCard
							xs={12}
							sm={6}
							md={4}
							lg={4}
							color="yellow"
							icon={<FlashOn />}
							title="RELÂMPAGO"
							subtitle={this.props.registro.relampago}
						/>

						<DashboardCard
							xs={12}
							sm={6}
							md={4}
							lg={4}
							color="orange"
							icon={<DeviceThermostat />}
							title="TEMPERATURA DA LUMINÁRIA"
							subtitle={this.props.registro.temperaturaLuminaria}
						/>
						<DashboardCard
							xs={12}
							sm={12}
							md={6}
							lg={6}
							color="purple"
							icon={<WbIncandescentIcon />}
							height={window.innerWidth <= 400 ? "max-content" : 400}
							paddingtop={4}
							title="POTÊNCIA DOS LEDS"
							component={
								<>
									<Grid
										container
										justifyContent="center"
										alignItems="center"
										spacing={1}
										style={{
											backgroundColor: "#ffffff",
										}}
									>
										<RadialChart
											data={[
												{
													value: this.props.registro.branco,
													color: "#ffffff",
												},
												{
													value: this.props.registro.azul,
													color: "#0055ff",
												},
												{
													value: this.props.registro.azulRoyal,
													color: "#4169e1",
												},
												{
													value: this.props.registro.uv,
													color: "#E164FE",
												},
											]}
											dimension={window.innerWidth <= 400 ? "100%" : 400}
											component={
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														borderRadius: "100%",
														minWidth: "100%",
														minHeight: "100%",
														boxShadow: "inset #aaaaaa 0 0 15px",
													}}
												>
													<div
														style={{
															// fontSize: window.innerWidth <= 400 ? 20 : 24,
															fontSize: window.innerWidth <= 400 ? 14 : 20,
															fontWeight: "bold",
															color: "#ffffff",
															textShadow: "-1px -1px 0 #999, 1px -1px 0 #999,-1px 1px 0 #999,1px 1px 0 #999",
														}}
													>
														{this.props.registro.branco}% Branco
													</div>
													<div
														style={{
															fontSize: window.innerWidth <= 400 ? 14 : 20,
															fontWeight: "bold",
															color: "#0055ff",
														}}
													>
														{this.props.registro.azul}% Azul
													</div>
													<div
														style={{
															fontSize: window.innerWidth <= 400 ? 14 : 20,
															fontWeight: "bold",
															color: "#4169e1",
														}}
													>
														{this.props.registro.azulRoyal}% Azul Royal
													</div>
													<div
														style={{
															fontSize: window.innerWidth <= 400 ? 14 : 20,
															fontWeight: "bold",
															color: "#E164FE",
														}}
													>
														{this.props.registro.uv}% U.V.
													</div>
												</div>
											}
										/>
									</Grid>
									<FormLabel style={{ width: "100%", textAlign: "right" }}>{this.props.registro.atualizado}</FormLabel>
								</>
							}
						/>
						<DashboardCard
							xs={12}
							sm={12}
							md={6}
							lg={6}
							color="green"
							icon={<ImageIcon />}
							title="IMAGEM DO AQUÁRIO"
							height={400}
							component={
								// !this.props.formularioValues.imagem_aquario ? (
								<>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "end",
											alignItems: "center",
										}}
									>
										<span
											className="btn fileinput-button dz-clickable"
											onClick={(e) => {
												this.setState({
													...this.state,
													imagem_aquario: null,
												});
											}}
											style={{
												marginLeft: "5px",
												maxWidth: "min-content",
												border: "none",
												marginTop: -85,
												marginRight: 5,
												zIndex: 2,
												display: (this.props.dispositivoSelecionado || {}).imagem ? "flex" : "none",
											}}
											title="Remover imagem"
										>
											<RemoveCircleOutlineIcon htmlColor={this.state.corBotaoRemoveImagem} />
										</span>
										<span
											className="btn fileinput-button dz-clickable"
											onClick={(e) => {
												this.fileSelectorImagem.click();
											}}
											style={{
												marginLeft: "5px",
												maxWidth: "min-content",
												border: "none",
												marginTop: -85,
												marginRight: 10,
												zIndex: 2,
												display: (this.props.dispositivoSelecionado || {}).imagem ? "none" : "flex",
											}}
											title="Adicionar imagem"
										>
											<AddPhotoAlternateIcon htmlColor={this.state.corBotaoAddImagem} />
										</span>
									</div>
									{(this.props.dispositivoSelecionado || {}).imagem ? (
										<div
											style={{
												height: 400,
												maxWidth: "100%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<img
												src={(this.props.dispositivoSelecionado || {}).imagem}
												alt="Imagem de perfil"
												style={{
													objectFit: "contain",
													maxWidth: "100%",
													maxHeight: "100%",
												}}
											/>
										</div>
									) : (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												height: 396,
											}}
										>
											<i
												className="fas fa-image"
												style={{
													fontSize: 200,
													color: "#777",
												}}
											></i>
										</div>
									)}
								</>
							}
						/>
					</Grid>
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	modoTela: state.dashboard.modoTela,
	registro: state.dashboard.registro,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			getDados,
			getListaDispositivo,
			alterarImagemDispositivo,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
