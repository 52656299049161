import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Select from "../common/form/select";
import Td from "../common/table/td";

import { IconButton, Button, Grid, FormControlLabel } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Slider from "@mui/material/Slider";

import { CustomSwitch } from "../common/components/CustomSwitch";
import { CustomButton } from "../common/components/CustomButton";

import CustomRadio from "../common/components/customRadio";
import { salvar, getDados } from "./amanhecerAnoitecerActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
import GraficoAmanhecerAnoitecer from "./amanhecerAnoitecerGrafico";

class AmanhecerAnoitecerForm extends Component {
	state = {
		exibirGrafico: false,
		modoIndividual: true, //FIXADO PRA SER SEMPRE MODO INDIVIDUAL, pra n precisar apagar a rotina antiga
		linhaSelecionada: null,
		canal: "branco",
		valorCanal: 0,
		horarioInicial: "00:00",
		horarioFinal: "00:30",
		branco: [],
		azul: [],
		azulRoyal: [],
		uv: [],
		periodos: { branco: [], azul: [], azulRoyal: [], uv: [] },
		listaHorariosCompleta: [],
		listaHorariosInicio: [],
		listaHorariosFim: [],
	};

	componentDidMount() {
		this.inicializarCanaisZerados();
		this.props.getListaDispositivo(this.props.sessao.id || 0);

		this.props.getDados();
		if (this.props.registro) {
			this.inicializarCanaisComValoresDoDispositivo(this.props.registro);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
				this.props.dispositivoSelecionado !== null) ||
			prevState.modoIndividual != this.state.modoIndividual
		) {
			this.props.getDados();
		}
		if (JSON.stringify(prevProps.registro) !== JSON.stringify(this.props.registro)) {
			if (this.props.registro) {
				this.inicializarCanaisComValoresDoDispositivo(this.props.registro);
			} else {
				this.inicializarCanaisZerados();
			}
		}

		if (prevState.canal !== this.state.canal && !this.state.modoIndividual) {
			const { periodosCanal, listaHorariosInicio, listaHorariosFim, horarioInicial, horarioFinal } = this.incluirHorarioInicioFimPeriodos(
				this.state[this.state.canal]
			);
			this.setState({
				...this.state,
				listaHorariosInicio,
				listaHorariosFim,
				horarioInicial,
				horarioFinal,
				periodos: {
					...this.state.periodos,
					[this.state.canal]: periodosCanal,
				},
			});
		}
	}

	getLocalStyles() {
		const coresCanal = {
			branco: "#FFFFFF",
			azul: "#0055ff",
			azulRoyal: "#4169e1",
			uv: "#E164FE",
		};

		return {
			root: {
				padding: 10,
				paddingBottom: 100,
			},
			gridButton: {
				marginTop: 20,
			},
			sliderHorizontal: {
				"& .MuiSlider-thumb": {
					color: coresCanal[this.state.canal],
					height: 30,
					width: 30,
				},
				"& .MuiSlider-track": {
					color: coresCanal[this.state.canal],
					height: 15,
				},
				"& .MuiSlider-rail": {
					color: coresCanal[this.state.canal],
					height: 15,
				},
			},
			sliderVertical: {
				"& .MuiSlider-thumb": {
					color: coresCanal[this.state.canal],
					height: 30,
					width: 30,
				},
				"& .MuiSlider-track": {
					color: coresCanal[this.state.canal],
					width: 15,
				},
				"& .MuiSlider-rail": {
					color: coresCanal[this.state.canal],
					width: 15,
				},
			},
		};
	}

	//inicializa todos os canais do estado contendo apenas o horário e o valor zero
	inicializarCanaisZerados() {
		const d = new Date();
		const trintaMin = 1000 * 60 * 30;
		d.setHours(0, 0, 0, 0);
		const listaHorariosZerada = [];

		for (let i = 0; i < 48; i++) {
			const data = new Date(d.getTime() + trintaMin * i);
			const horario = data.toLocaleTimeString("pt-BR", {
				timeStyle: "short",
			});
			listaHorariosZerada.push({ horario, valor: 0 });
		}
		const listaHorariosTemporaria = [...listaHorariosZerada];
		listaHorariosTemporaria.push({
			horario: "23:59",
			valor: 0,
		});
		this.setState({
			periodos: {
				branco: [],
				azul: [],
				azulRoyal: [],
				uv: [],
			},
			branco: [...listaHorariosZerada],
			azul: [...listaHorariosZerada],
			azulRoyal: [...listaHorariosZerada],
			uv: [...listaHorariosZerada],
			extra: [...listaHorariosZerada],
			listaHorariosCompleta: [...listaHorariosTemporaria],
			listaHorariosInicio: [...listaHorariosTemporaria],
			listaHorariosFim: [...listaHorariosTemporaria],
		});
	}

	//consulta o dispositivo e carrega os dados no estado do componente
	inicializarCanaisComValoresDoDispositivo({ branco, azul, azulRoyal, uv }) {
		const d = new Date();
		const trintaMin = 1000 * 60 * 30;
		d.setHours(0, 0, 0, 0);

		const [canalBranco, canalAzul, canalAzulRoyal, canalUV] = [[], [], [], []];

		for (let i = 0; i < 48; i++) {
			const data = new Date(d.getTime() + trintaMin * i);
			const horario = data.toLocaleTimeString("pt-BR", {
				timeStyle: "short",
			});

			canalBranco.push({ horario, valor: branco[i] });
			canalAzul.push({ horario, valor: azul[i] });
			canalAzulRoyal.push({ horario, valor: azulRoyal[i] });
			canalUV.push({ horario, valor: uv[i] });
		}

		const dadosCanalPadrao = this.incluirHorarioInicioFimPeriodos([...canalBranco]);
		const stateAtualizado = {
			...this.state,
			branco: [...canalBranco],
			azul: [...canalAzul],
			azulRoyal: [...canalAzulRoyal],
			uv: [...canalUV],
			listaHorariosInicio: dadosCanalPadrao.listaHorariosInicio,
			listaHorariosFim: dadosCanalPadrao.listaHorariosFim,
			horarioInicial: dadosCanalPadrao.horarioInicial,
			horarioFinal: dadosCanalPadrao.horarioFinal,
			periodos: {
				branco: dadosCanalPadrao.periodosCanal,
				azul: this.incluirHorarioInicioFimPeriodos([...canalAzul]).periodosCanal,
				azulRoyal: this.incluirHorarioInicioFimPeriodos([...canalAzulRoyal]).periodosCanal,
				uv: this.incluirHorarioInicioFimPeriodos([...canalUV]).periodosCanal,
			},
		};
		this.setState(stateAtualizado);
	}

	//cria a lista para o canal em questão, especificando o horario de inicio e fim para o valor informado
	incluirHorarioInicioFimPeriodos(listaCanal) {
		const periodosCanal = [];
		const listaHorariosInicio = [];
		let registro = {};

		for (let i = 0; i < listaCanal.length; i++) {
			//se o valor for zero ignora e vai para o próximo
			if (listaCanal[i].valor === 0) {
				listaHorariosInicio.push(listaCanal[i]);
				continue;
			}

			if (listaCanal[i].valor !== registro.valor) {
				registro.horarioInicial = listaCanal[i].horario;
				registro.valor = listaCanal[i].valor;
			}

			//se o valor do atual for igual ao próximo continua, deixando o horario final em aberto
			if (i < listaCanal.length - 1 && listaCanal[i].valor === listaCanal[i + 1].valor) continue;

			//se for o último da lista define o último horário final possível
			if (i === listaCanal.length - 1) registro.horarioFinal = "23:59";

			//se o próximo valor for diferente do atual salva o horário do próximo como horario final do atual
			if (i < listaCanal.length - 1 && listaCanal[i].valor !== listaCanal[i + 1].valor) registro.horarioFinal = listaCanal[i + 1].horario;

			if (registro.horarioFinal) {
				periodosCanal.push(registro);
				registro = {};
			}
		}

		const horarioInicial = listaHorariosInicio[0] ? listaHorariosInicio[0].horario : null;

		const listaHorariosFim = this.filtrarListaHorarioFinal(horarioInicial, listaCanal);

		const horarioFinal = listaHorariosFim[0] ? listaHorariosFim[0].horario : null;

		return {
			periodosCanal,
			listaHorariosInicio,
			listaHorariosFim,
			horarioInicial,
			horarioFinal,
		};
	}

	//popula a lista usada no select horário final
	definirListaHorarioFinal(horarioInicial) {
		const listaHorariosFim = this.filtrarListaHorarioFinal(horarioInicial, this.state[this.state.canal]);
		const horarioFinal = listaHorariosFim[0] ? listaHorariosFim[0].horario : null;

		this.setState({
			...this.state,
			listaHorariosFim,
			horarioInicial,
			horarioFinal,
		});
	}

	//seleciona horários finais para serem incluídos no select
	filtrarListaHorarioFinal(horarioInicial, listaCanal) {
		if (!horarioInicial) return [];
		const dataHoraInicial = this.converterHorarioTextoParaData(horarioInicial).setSeconds(0, 0);
		const itemComValor = listaCanal.find(
			(elemento) => elemento.valor !== 0 && this.converterHorarioTextoParaData(elemento.horario).setSeconds(0, 0) > dataHoraInicial
		);

		const listaHorariosFim = this.state.listaHorariosCompleta.filter((item) => {
			const dataHoraItem = this.converterHorarioTextoParaData(item.horario).setSeconds(0, 0);
			if (itemComValor && dataHoraItem > this.converterHorarioTextoParaData(itemComValor.horario).setSeconds(0, 0)) {
				return;
			}
			return dataHoraItem > dataHoraInicial;
		});
		return listaHorariosFim;
	}

	//usada para poder comparar horários
	converterHorarioTextoParaData(horario) {
		const dataHora = new Date();
		const horas = parseInt(horario.split(":")[0]);
		const minutos = parseInt(horario.split(":")[1]);
		dataHora.setHours(horas, minutos);
		return dataHora;
	}

	//deixa a lista de valores vazia para o canal especificado
	limparValoresCanal(canal) {
		const canaisResetados = this.state[canal].map((item) => ({
			...item,
			valor: 0,
		}));

		this.setState({
			...this.state,
			[canal]: [...canaisResetados],
			periodos: { ...this.state.periodos, [canal]: [] },
			valorCanal: 0,
			canal,
			listaHorariosInicio: [...canaisResetados],
			listaHorariosFim: [...canaisResetados].slice(1),
			horarioInicial: "00:00",
			horarioFinal: "00:30",
		});
	}

	//função acionada pelo botão "INCLUIR"
	incluirValorListaCanal() {
		//constrói a lista de cada canal, modificando os registros alterados e incluidos
		const horariosComValor = this.state[this.state.canal].map((item) => {
			const dataHoraInicial = this.converterHorarioTextoParaData(this.state.horarioInicial);
			const dataHoraFinal = this.converterHorarioTextoParaData(this.state.horarioFinal);

			//para todos elementos, do horario inicial ao final, atualiza o valor
			const dataHoraItem = this.converterHorarioTextoParaData(item.horario);
			if (dataHoraItem >= dataHoraInicial && dataHoraItem < dataHoraFinal) {
				const itemAlterado = {
					horario: item.horario,
					valor: this.state.valorCanal,
				};
				return itemAlterado;
			}
			return item;
		});

		const { periodosCanal, listaHorariosInicio, listaHorariosFim } = this.incluirHorarioInicioFimPeriodos(horariosComValor);

		const horarioInicial = listaHorariosInicio.length ? listaHorariosInicio[0].horario : null;

		const horarioFinal = listaHorariosFim.length ? listaHorariosFim[0].horario : null;

		this.setState({
			...this.state,
			[this.state.canal]: horariosComValor,
			horarioInicial,
			horarioFinal,
			listaHorariosInicio,
			listaHorariosFim,
			periodos: {
				...this.state.periodos,
				[this.state.canal]: [...periodosCanal],
			},
		});
	}

	//função acionada pelo botão com ícone de lixeira,
	//serve para remover um dos periodos cadastrados
	removerHorarioPeriodos(horarioInicialParam, horarioFinalParam) {
		const listaCanal = this.state[this.state.canal].map((item) => {
			const dataHorarioInicial = this.converterHorarioTextoParaData(horarioInicialParam);
			const dataHorarioFinal = this.converterHorarioTextoParaData(horarioFinalParam);
			const dataHorarioItem = this.converterHorarioTextoParaData(item.horario);

			// horario == horarioInicial

			if (dataHorarioItem >= dataHorarioInicial && dataHorarioItem < dataHorarioFinal) return { ...item, valor: 0 };
			return item;
		});

		const { periodosCanal, listaHorariosInicio, listaHorariosFim, horarioInicial, horarioFinal } = this.incluirHorarioInicioFimPeriodos(listaCanal);
		this.setState({
			...this.state,
			[this.state.canal]: [...listaCanal],
			listaHorariosInicio,
			listaHorariosFim,
			horarioInicial,
			horarioFinal,
			periodos: {
				...this.state.periodos,
				[this.state.canal]: periodosCanal,
			},
		});
	}

	render() {
		const coresCanal = {
			branco: "#FFFFFF",
			azul: "#0055ff",
			azulRoyal: "#4169e1",
			uv: "#E164FE",
		};
		const style = this.getLocalStyles();
		const modoLeitura = !!(this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.id_dispositivo_pai);
		return (
			<Grid
				container
				spacing={1}
				columns={12}
				style={{
					paddingInline: "5%",
				}}
			>
				{this.state.exibirGrafico ? null : (
					<div
						justifyContent="start"
						style={{
							paddingTop: 60,
							paddingBottom: 20,
							color: "#4db6ac",
						}}
					>
						<h4 style={{ paddingLeft: 20 }}>Selecione um canal para alterar {modoLeitura && "(Modo Leitura)"}</h4>
					</div>
				)}
				{this.state.exibirGrafico ? null : (
					<Grid
						container
						justifyContent="space-between"
						style={{
							padding: 20,
							borderRadius: 10,
						}}
					>
						<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
							<label
								htmlFor="branco"
								style={{
									fontWeight: "normal",
									paddingBlock: 10,
									paddingRight: 15,
									borderRadius: 8,
									marginBottom: 10,
									width: "96%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: coresCanal["branco"],
									color: "#000000",
									boxShadow: "#777777 1px 2px 5px",
								}}
							>
								<CustomRadio
									checked={this.state.canal === "branco"}
									customColor="#4db6ac"
									value="branco"
									id="branco"
									disabled={modoLeitura}
									onChange={(e) =>
										this.setState({
											...this.state,
											canal: e.target.value,
										})
									}
								/>
								Branco
							</label>
						</Grid>
						<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
							<label
								htmlFor="azul"
								style={{
									fontWeight: "normal",
									paddingBlock: 10,
									paddingRight: 15,
									borderRadius: 8,
									marginBottom: 10,
									width: "96%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: coresCanal["azul"],
									boxShadow: "#777777 1px 2px 5px",
									color: "#ffffff",
								}}
							>
								<CustomRadio
									checked={this.state.canal === "azul"}
									value="azul"
									id="azul"
									disabled={modoLeitura}
									onChange={(e) =>
										this.setState({
											...this.state,
											canal: e.target.value,
										})
									}
								/>
								Azul
							</label>
						</Grid>
						<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
							<label
								htmlFor="azulRoyal"
								style={{
									fontWeight: "normal",
									paddingBlock: 10,
									paddingRight: 15,
									borderRadius: 8,
									marginBottom: 10,
									width: "96%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: coresCanal["azulRoyal"],
									boxShadow: "#777777 1px 2px 5px",
									color: "#ffffff",
								}}
							>
								<CustomRadio
									checked={this.state.canal === "azulRoyal"}
									id="azulRoyal"
									value="azulRoyal"
									disabled={modoLeitura}
									onChange={(e) =>
										this.setState({
											...this.state,
											canal: e.target.value,
										})
									}
								/>
								Azul Royal
							</label>
						</Grid>
						<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
							<label
								htmlFor="uv"
								style={{
									fontWeight: "normal",
									paddingBlock: 10,
									paddingRight: 15,
									borderRadius: 8,
									marginBottom: 10,
									width: "96%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: coresCanal["uv"],
									boxShadow: "#777777 1px 2px 5px",
									color: "#ffffff",
								}}
							>
								<CustomRadio
									id="uv"
									checked={this.state.canal === "uv"}
									value="uv"
									disabled={modoLeitura}
									onChange={(e) =>
										this.setState({
											...this.state,
											canal: e.target.value,
										})
									}
								/>
								U.V.
							</label>
						</Grid>
					</Grid>
				)}
				<Grid container spacing={1} columns={12} style={{ padding: 20 }}>
					{/* {this.state.exibirGrafico ? null : (
						<Grid item xs={12} sm={6} md={10}>
							<FormControlLabel
								required
								control={
									<CustomSwitch
										checked={this.state.modoIndividual}
										disabled={modoLeitura}
										onChange={(e) =>
											this.setState({
												...this.state,
												modoIndividual: e.target.checked,
											})
										}
									/>
								}
								label={this.state.modoIndividual ? "Configurar por intervalo de tempo" : "Configurar horários individualmente"}
							/>
						</Grid>
					)} */}
					<Grid item xs={12} sm={6} md={2} style={{ paddingTop: 70 }}>
						{this.state.exibirGrafico ? (
							<CustomButton
								color="#FF5555"
								fullWidth={true}
								variant="outlined"
								onClick={() => this.setState({ ...this.state, exibirGrafico: !this.state.exibirGrafico })}
							>
								Ocultar Gráfico
							</CustomButton>
						) : (
							<CustomButton
								color="#0066FF"
								fullWidth={true}
								variant="outlined"
								onClick={() => this.setState({ ...this.state, exibirGrafico: !this.state.exibirGrafico })}
							>
								Exibir Gráfico
							</CustomButton>
						)}
					</Grid>
				</Grid>
				{this.state.exibirGrafico ? (
					<Grid
						container
						spacing={1}
						columns={12}
						justifyContent={window.innerWidth < 768 ? "left" : "center"}
						style={{ paddingInline: 20, overflowX: "scroll" }}
					>
						<GraficoAmanhecerAnoitecer
							seriesData={[
								{ name: "Branco", field: "branco", data: this.state.branco, color: coresCanal["branco"] },
								{ name: "Azul", field: "azul", data: this.state.azul, color: coresCanal["azul"] },
								{ name: "Azul Royal", field: "azulRoyal", data: this.state.azulRoyal, color: coresCanal["azulRoyal"] },
								{ name: "UV", field: "uv", data: this.state.uv, color: coresCanal["uv"] },
							]}
						/>
					</Grid>
				) : this.state.modoIndividual ? (
					<Grid
						container
						spacing={1}
						columns={12}
						justifyContent="center"
						style={{
							height: 300,
							display: "flex",
							justifyContent: "flex-start",
							flexWrap: "nowrap",
							overflowX: "scroll",
							padding: 20,
							backgroundColor: "#EEEEEE",
							borderRadius: "0.3rem",
						}}
					>
						{this.state[this.state.canal].map((item, indice) => {
							return (
								<div
									style={{
										marginInline: 10,
										display: "flex",
										alignItems: "center",
										flexDirection: "column",
									}}
								>
									<span style={{ width: 50, margin: 10, textAlign: "center" }}>{((item.valor * 100) / 1023).toFixed(0) + " %"}</span>
									<Slider
										sx={style.sliderVertical}
										style={{ backgroundColor: "#EEEEEE" }}
										orientation="vertical"
										valueLabelDisplay="auto"
										value={item.valor}
										min={0}
										max={1023}
										disabled={modoLeitura}
										defaultValue={0}
										onChange={(e, val) => {
											const listaAtualizada = JSON.parse(JSON.stringify(this.state[this.state.canal]));
											listaAtualizada[indice] = { ...item, valor: val };
											this.setState({ ...this.state, [this.state.canal]: listaAtualizada });
										}}
									/>
									<span
										style={{
											minWidth: "fit-content",
											marginTop: 30,
										}}
									>
										{item.horario}
									</span>
								</div>
							);
						})}
					</Grid>
				) : (
					<Grid container spacing={1} columns={12} justifyContent="center" style={{ padding: 20 }}>
						<Grid item xs={12} md={2}>
							<Select
								name="horario-inicial"
								clearable={false}
								options={this.state.listaHorariosInicio
									.filter((item) => item.horario !== "23:59")
									.map((item) => ({
										id: item.horario,
										valor: item.horario,
									}))}
								placeholder={!this.state.horarioInicial && "--:--"}
								value={this.state.horarioInicial}
								onChange={(e) => {
									const horarioInicial = e;
									this.definirListaHorarioFinal(horarioInicial, this.state.canal);
								}}
								disabled={!this.state.horarioInicial || modoLeitura}
							/>
						</Grid>
						<Grid item xs={12} md={2}>
							<Select
								name="horario-final"
								clearable={false}
								options={this.state.listaHorariosFim
									.filter((item) => item.horario !== "00:00")
									.map((item) => ({
										id: item.horario,
										valor: item.horario === "23:59" ? "00:00" : item.horario,
									}))}
								placeholder={!this.state.horarioFinal && "--:--"}
								value={this.state.horarioFinal}
								onChange={(e) => {
									this.setState({ ...this.state, horarioFinal: e });
								}}
								disabled={!this.state.horarioFinal || modoLeitura}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							style={{
								padding: 10,
								paddingTop: window.innerWidth <= 600 ? 0 : 32,
							}}
						>
							<span
								style={{
									marginInline: 10,
									display: "flex",
									flexWrap: "nowrap",
								}}
							>
								<Slider
									sx={style.sliderHorizontal}
									style={{ marginLeft: 15, marginRight: 35, backgroundColor: "#EEEEEE" }}
									valueLabelDisplay="auto"
									value={this.state.valorCanal}
									min={0}
									max={1023}
									disabled={modoLeitura}
									defaultValue={0}
									onChange={(e, val) => {
										this.setState({ ...this.state, valorCanal: val });
									}}
								/>
								<span
									style={{
										minWidth: "fit-content",
										marginInline: 10,
									}}
								>
									{((this.state.valorCanal * 100) / 1023).toFixed(0) + " %"}
								</span>
							</span>
						</Grid>
						<Grid item xs={12} md={2} style={{ paddingRight: 10, paddingTop: 30 }}>
							<Button
								fullWidth
								disabled={!this.props.registro || this.state.valorCanal <= 0 || !this.state.horarioInicial || !this.state.horarioFinal || modoLeitura}
								variant="outlined"
								color="primary"
								onClick={() => {
									this.incluirValorListaCanal();
								}}
							>
								Incluir
							</Button>
						</Grid>

						<Grid item xs={12}>
							<Table
								responsive
								style={{
									backgroundColor: "#ffffff",
								}}
							>
								<THead>
									<Tr>
										<Th sticky alignCenter>
											Horário Inicial
										</Th>
										<Th sticky alignCenter>
											Horário Final
										</Th>
										<Th sticky alignCenter>
											Valor
										</Th>
										<Th sticky alignCenter></Th>
									</Tr>
								</THead>

								<TBody>
									{this.state.periodos[this.state.canal].map((item, i) => (
										<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? "hover" : ""}`}>
											<Td verticalAlign="top" alignCenter>
												{item.horarioInicial}
											</Td>
											<Td verticalAlign="top" alignCenter>
												{item.horarioFinal === "23:59" ? "00:00" : item.horarioFinal}
											</Td>
											<Td verticalAlign="top" alignCenter>
												{((item.valor * 100) / 1023).toFixed(0) + " %"}
											</Td>
											<Td alignCenter>
												<IconButton
													title="Remover"
													aria-label="delete"
													disabled={modoLeitura}
													onClick={() => {
														this.removerHorarioPeriodos(item.horarioInicial, item.horarioFinal);
													}}
												>
													<DeleteIcon />
												</IconButton>
											</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</Grid>
					</Grid>
				)}
				<Grid
					container
					style={{
						...style.gridButton,
						padding: 20,
						marginBottom: 100,
					}}
					direction="row"
					justifyContent="center"
				>
					<Grid item xs={12} md={2} style={{ padding: 10 }}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={!this.props.registro || modoLeitura}
							onClick={() => this.props.salvar(this.state)}
						>
							Salvar
						</Button>
					</Grid>
					<Grid item xs={12} md={4} style={{ padding: 10 }}>
						<CustomButton
							fullWidth={true}
							variant="outlined"
							color="#ff5555"
							disabled={!this.props.registro || modoLeitura}
							onClick={() => {
								this.limparValoresCanal(this.state.canal);
							}}
						>
							Limpar Valores do Canal
						</CustomButton>
					</Grid>
					<Grid item xs={12} md={2} style={{ padding: 10 }}>
						<CustomButton color="#0066ff" fullWidth={true} variant="outlined" href="#/">
							Voltar
						</CustomButton>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.amanhecerAnoitecer.registro,
	// registro: {
	// 	branco: [
	// 		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 510, 311, 712, 811, 910, 1009, 608,
	// 		407, 86, 5, 4, 3, 2,
	// 	],
	// 	azul: [
	// 		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 510, 311, 712, 811, 910, 1009, 608,
	// 		407, 86, 5, 4, 3, 2,
	// 	],
	// 	azulRoyal: [
	// 		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 510, 311, 712, 811, 910, 1009, 608,
	// 		407, 86, 5, 4, 3, 2,
	// 	],
	// 	uv: [
	// 		0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 510, 311, 712, 811, 910, 1009, 608,
	// 		407, 86, 5, 4, 3, 2,
	// 	],
	// },
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ salvar, getDados, getListaDispositivo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AmanhecerAnoitecerForm);
