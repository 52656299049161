const INITIAL_STATE = {
  carregando: true,
  registro: {
    brancoLuaNova: 0,
    brancoLuaCheia: 0,
    azulLuaNova: 0,
    azulLuaCheia: 0,
    azulRoyalLuaNova: 0,
    azulRoyalLuaCheia: 0,
    uvLuaNova: 0,
    uvLuaCheia: 0,
    desativar: false,
    horarioInicial: "00:00",
    horarioFinal: "00:00",
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LUNAR_LISTADO":
      const registro = {
        brancoLuaNova: action.payload.data.bNova,
        brancoLuaCheia: action.payload.data.bCheia,
        azulLuaNova: action.payload.data.aNova,
        azulLuaCheia: action.payload.data.aCheia,
        azulRoyalLuaNova: action.payload.data.aRNova,
        azulRoyalLuaCheia: action.payload.data.aRCheia,
        uvLuaNova: action.payload.data.uvNova,
        uvLuaCheia: action.payload.data.uvCheia,
        horarioInicial: action.payload.data.inicio,
        horarioFinal: action.payload.data.fim,
        desativar: action.payload.data.desativar,
      };
      if (action.payload.data.eNova >= 0 || action.payload.data.eCheia >= 0) {
        registro.extraLuaNova = 0;
        registro.extraLuaCheia = 0;
      }
      // console.log(JSON.stringify(registro));
      return {
        ...state,
        carregando: false,
        registro: registro,
      };
    case "RESETAR_LUNAR":
      return INITIAL_STATE;
    default:
      return state;
  }
};
