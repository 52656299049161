import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid, Typography } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { CustomSwitch } from "../common/components/CustomSwitch";
import { CustomButton } from "../common/components/CustomButton";
import { setModoTela } from "../dashboard/dashboardActions";
import { getDados, salvar, executarAgora } from "./climaActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";

class ClimaForm extends Component {
	state = {
		branco: false,
		azul: false,
		azulRoyal: false,
		uv: false,
		intervaloDias: 1,
		probabilidadeNuvem: 0,
		quantidadeMinMaxDia: [0, 0],
		duracaoMinMaxDia: [0, 0],
		probabilidadeRelampago: 0,
		executou: false,
	};

	async componentDidMount() {
		await this.props.getListaDispositivo(this.props.sessao.id || 0);
		if (!this.state.executou) {
			await this.props.getDados();
			this.setState({ ...this.props.registro, executou: true });
		}
	}
	async componentDidUpdate(prevProps) {
		if (
			JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
			prevProps.dispositivoSelecionado !== null
		) {
			await this.props.getDados();
			this.setState({ ...this.props.registro, executou: true });
		}
	}
	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100,
			},
			gridButton: {
				marginTop: 20,
			},
			sliderColor: {
				"& .MuiSlider-thumb": {
					color: "#4db6ac",
					height: 30,
					width: 30,
				},
				"& .MuiSlider-track": {
					color: "#4db6ac",
					height: 15,
				},
				"& .MuiSlider-rail": {
					color: "#4db6ac",
					height: 15,
				},
			},
		};
	}

	render() {
		const style = this.getLocalStyles();
		const modoLeitura = !!(this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.id_dispositivo_pai);
		return (
			<Grid
				container
				spacing={1}
				columns={12}
				style={{
					paddingInline: "5%",
				}}
			>
				<div
					justifyContent="start"
					style={{
						paddingTop: 60,
						paddingBottom: 20,
						color: "#4db6ac",
					}}
				>
					<h4>
						<i className="fa fa-cloud" style={{ paddingLeft: 20, marginRight: 10 }}></i>
						Efeito Nuvem {modoLeitura && "(Modo Leitura)"}
					</h4>
				</div>
				<Grid
					container
					justifyContent="space-between"
					style={{
						padding: 20,
						borderRadius: 10,
					}}
				>
					<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
						<div
							style={{
								paddingBlock: 10,
								paddingLeft: 20,
								borderRadius: 8,
								marginBottom: 30,
								width: "96%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#ffffff",
								color: "#000000",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Branco
							<CustomSwitch
								checked={this.state.branco}
								disabled={modoLeitura}
								onChange={(e) =>
									this.setState({
										...this.state,
										branco: e.target.checked,
									})
								}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
						<div
							style={{
								paddingBlock: 10,
								paddingLeft: 20,
								borderRadius: 8,
								marginBottom: 30,
								width: "96%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#0055ff",
								boxShadow: "#777777 1px 2px 5px",
								color: "#ffffff",
							}}
						>
							Azul
							<CustomSwitch
								customColor="#ffffff"
								checked={this.state.azul}
								disabled={modoLeitura}
								onChange={(e) =>
									this.setState({
										...this.state,
										azul: e.target.checked,
									})
								}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
						<div
							style={{
								paddingBlock: 10,
								paddingLeft: 20,
								borderRadius: 8,
								marginBottom: 30,
								width: "96%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#4169e1",
								boxShadow: "#777777 1px 2px 5px",
								color: "#ffffff",
							}}
						>
							Azul Royal
							<CustomSwitch
								customColor="#ffffff"
								checked={this.state.azulRoyal}
								disabled={modoLeitura}
								onChange={(e) =>
									this.setState({
										...this.state,
										azulRoyal: e.target.checked,
									})
								}
							/>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={3} style={{ display: "flex", justifyContent: "center" }}>
						<div
							style={{
								paddingBlock: 10,
								paddingLeft: 20,
								borderRadius: 8,
								marginBottom: 30,
								width: "96%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#E164FE",
								boxShadow: "#777777 1px 2px 5px",
								color: "#ffffff",
							}}
						>
							U.V.
							<CustomSwitch
								customColor="#ffffff"
								checked={this.state.uv}
								disabled={modoLeitura}
								onChange={(e) =>
									this.setState({
										...this.state,
										uv: e.target.checked,
									})
								}
							/>
						</div>
					</Grid>
					<Grid item xs={9} sm={10} md={11} style={{ paddingRight: 12, paddingTop: 20 }}>
						<Typography id="intervalo-dias" gutterBottom>
							Executar a cada:
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style.sliderColor}
								aria-labelledby="intervalo-dias"
								style={{ marginRight: 5 }}
								valueLabelDisplay="auto"
								min={1}
								max={9}
								disabled={modoLeitura}
								value={this.state.intervaloDias}
								onChange={(e, val) => {
									this.setState({ ...this.state, intervaloDias: val });
								}}
							/>
						</span>
					</Grid>
					<Grid item xs={3} sm={2} md={1} style={{ paddingTop: 52, paddingLeft: 25 }}>
						{this.state.intervaloDias + (this.state.intervaloDias > 1 ? " dias" : " dia")}
					</Grid>
					<Grid item xs={9} sm={10} md={11} style={{ paddingRight: 12, paddingTop: 20 }}>
						<Typography id="probabilidade-nuvem" gutterBottom>
							Probabilidade do Efeito de Nuvem:
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style.sliderColor}
								aria-labelledby="probabilidade-nuvem"
								style={{ marginRight: 5 }}
								valueLabelDisplay="auto"
								min={0}
								max={100}
								disabled={modoLeitura}
								value={this.state.probabilidadeNuvem}
								onChange={(e, val) => {
									this.setState({ ...this.state, probabilidadeNuvem: val });
								}}
							/>
						</span>
					</Grid>
					<Grid
						item
						xs={3}
						sm={2}
						md={1}
						style={{
							paddingTop: window.innerWidth < 439 ? 80 : 52,
							paddingLeft: 25,
						}}
					>
						{this.state.probabilidadeNuvem + " %"}
					</Grid>
					<Grid item xs={9} sm={10} md={11} style={{ paddingRight: 12, paddingTop: 20 }}>
						<Typography id="quantidade-min-max-dia" gutterBottom>
							Quantidade Min. e Máx. por dia:
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style.sliderColor}
								aria-labelledby="quantidade-min-max-dia"
								style={{ marginRight: 5 }}
								valueLabelDisplay="auto"
								min={0}
								max={15}
								disabled={modoLeitura}
								value={this.state.quantidadeMinMaxDia}
								onChange={(e, val) => {
									this.setState({ ...this.state, quantidadeMinMaxDia: val });
								}}
							/>
						</span>
					</Grid>
					<Grid
						item
						xs={3}
						sm={2}
						md={1}
						style={{
							paddingTop: window.innerWidth < 409 ? 80 : 52,
							paddingLeft: 25,
						}}
					>
						{this.state.quantidadeMinMaxDia[0] + " à " + this.state.quantidadeMinMaxDia[1]}
					</Grid>
					<Grid item xs={9} sm={10} md={11} style={{ paddingRight: 12, paddingTop: 20 }}>
						<Typography id="duracao-min-max-dia" gutterBottom>
							Duração Min. e Máx. por dia:
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style.sliderColor}
								aria-labelledby="duracao-min-max-dia"
								style={{ marginRight: 5 }}
								valueLabelDisplay="auto"
								min={0}
								max={15}
								disabled={modoLeitura}
								value={this.state.duracaoMinMaxDia}
								onChange={(e, val) => {
									this.setState({ ...this.state, duracaoMinMaxDia: val });
								}}
							/>
						</span>
					</Grid>
					<Grid
						item
						xs={3}
						sm={2}
						md={1}
						style={{
							paddingTop: window.innerWidth < 375 ? 80 : 52,
							paddingLeft: 25,
						}}
					>
						{this.state.duracaoMinMaxDia[0] + " à " + this.state.duracaoMinMaxDia[1]}
					</Grid>
				</Grid>
				<div
					justifyContent="start"
					style={{
						paddingTop: 60,
						color: "#4db6ac",
					}}
				>
					<h4>
						<i className="fa fa-bolt" style={{ paddingLeft: 20, marginRight: 10 }}></i>
						Efeito Relâmpago {modoLeitura && "(Modo Leitura)"}
					</h4>
				</div>
				<Grid
					container
					style={{
						padding: 20,
						borderRadius: 10,
					}}
				>
					<Grid item xs={9} sm={10} md={11} style={{ paddingRight: 12, paddingTop: 20 }}>
						<Typography id="probabilidade-relampago" gutterBottom>
							Probabilidade do Efeito de Relâmpago:
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style.sliderColor}
								aria-labelledby="probabilidade-relampago"
								style={{ marginRight: 5 }}
								valueLabelDisplay="auto"
								min={0}
								max={100}
								disabled={modoLeitura}
								value={this.state.probabilidadeRelampago}
								onChange={(e, val) => {
									this.setState({ ...this.state, probabilidadeRelampago: val });
								}}
							/>
						</span>
					</Grid>
					<Grid
						item
						xs={3}
						sm={2}
						md={1}
						style={{
							paddingTop: window.innerWidth < 484 ? 80 : 52,
							paddingLeft: 25,
							justifyContent: "end",
						}}
					>
						{this.state.probabilidadeRelampago + " %"}
					</Grid>
				</Grid>

				<Grid container style={{ ...style.gridButton, paddingBlock: 20, marginBottom: 100 }} direction="row" justifyContent="center">
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={this.props.carregando || modoLeitura}
							onClick={() => this.props.salvar(this.state)}
						>
							Salvar
						</Button>
					</Grid>
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<Button
							fullWidth
							variant="contained"
							color="secondary"
							disabled={this.props.carregando || modoLeitura}
							onClick={() => this.props.executarAgora()}
						>
							Executar agora
						</Button>
					</Grid>
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<CustomButton color="#0066ff" fullWidth={true} variant="outlined" href="#/">
							Voltar
						</CustomButton>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.clima.registro,
	carregando: state.clima.carregando,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, getDados, salvar, getListaDispositivo, executarAgora }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClimaForm);
