import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./radialChart.css";

const DEFAULT_COLOR = "#040404";
class RadialChart extends Component {
  state = {};

  componentDidMount() {
    // For initial animation
    setTimeout(() => {
      this.setState({ setStrokeLength: true });
    });
  }
  render() {
    const { setStrokeLength } = this.state;
    const { radius, data, strokeWidth, dimension, color, component } =
      this.props;
    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * 3.14 * circleRadius;

    let size = 160;

    return (
      <div
        className={classNames("radial-chart", {
          "no-progress": false, //strokeLength === 0
        })}
      >
        {data.map((item, i, a) => {
          const strokeLength = setStrokeLength
            ? (circumference / 100) * item.value
            : 0;

          size += 30 + i * i;

          return (
            <svg
              key={i}
              viewBox={`0 0 ${size} ${size}`}
              width={dimension}
              height={dimension}
              style={{
                position: "absolute",
              }}
            >
              <circle
                className="radial-chart-total"
                stroke={item.color === "#ffffff" ? "#555" : item.color}
                strokeWidth={strokeWidth}
                fill="none"
                cx={size / 2}
                cy={size / 2}
                r={circleRadius}
              />
              <circle
                className="radial-chart-progress"
                stroke={item.color === "#ffffff" ? "#aaa" : item.color}
                strokeWidth={strokeWidth}
                strokeDasharray={`${strokeLength},${circumference}`}
                strokeLinecap="round"
                fill="none"
                cx={size / 2}
                cy={size / 2}
                r={circleRadius}
              />
              {item.color === "#ffffff" ? (
                <circle
                  className="radial-chart-progress"
                  stroke={item.color}
                  strokeWidth={strokeWidth - 1}
                  strokeDasharray={`${strokeLength},${circumference}`}
                  strokeLinecap="round"
                  fill="none"
                  cx={size / 2}
                  cy={size / 2}
                  r={circleRadius}
                />
              ) : null}
            </svg>
          );
        })}

        <svg viewBox="0 0 240 240" width={dimension} height={dimension}></svg>

        <div
          style={{
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              padding: "26%",
            }}
          >
            {component}
          </div>
        </div>
      </div>
    );
  }
}

RadialChart.defaultProps = {
  radius: 80,
  progress: 100,
  strokeWidth: 10,
  dimension: 180,
  color: DEFAULT_COLOR,
};

export default RadialChart;
