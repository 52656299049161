import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";

export function setModoTela(modo, registro = {}) {
	return {
		type: "DISPOSITIVO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}
export function initForm(registro = {}) {
	return [initialize("dispositivoForm", registro)];
}

export async function getLista(id_usuario) {
	return async (dispatch, getState) => {
		if (!id_usuario) return {};

		try {
			const respostaDispositivos = await axios.get(`${consts.API_URL}/dispositivo?id_usuario=${id_usuario}`);
			const dispositivos = respostaDispositivos.data;
			const respostaDependentes = await axios.get(`${consts.API_URL}/dispositivoDependente`);
			const dependentes = respostaDependentes.data;

			// adiciona a cada dispositivo a lista de ids dos filhos e o id do pai {
			for (let i = 0; i < dispositivos.length; i++) {
				const IDsDispositivosFilhos = dependentes
					.filter((item) => item.id_dispositivo_pai === dispositivos[i].id)
					.map((item) => item.id_dispositivo_filho);

				//retorna id, nome e serie de cada filho
				const dispositivos_filhos = dispositivos
					.filter((item) => IDsDispositivosFilhos.includes(item.id))
					.map((item) => {
						return {
							id: item.id,
							nome: item.nome,
							serie: item.serie,
						};
					});

				//retorna id, nome e serie do pai, se existir
				const IDDispositivoPai = dependentes.find((item) => item.id_dispositivo_filho === dispositivos[i].id);
				const dispositivo_pai = {
					id_dispositivo_pai: null,
					nome_dispositivo_pai: null,
					serie_dispositivo_pai: null,
				};
				if (IDDispositivoPai) {
					const pai = dispositivos.find((item) => item.id === IDDispositivoPai.id_dispositivo_pai) || {};
					dispositivo_pai.id_dispositivo_pai = pai.id || null;
					dispositivo_pai.nome_dispositivo_pai = pai.nome || null;
					dispositivo_pai.serie_dispositivo_pai = pai.serie || null;
				}

				dispositivos[i] = {
					...dispositivos[i],
					dispositivos_filhos,
					...dispositivo_pai,
				};
			}
			dispatch({
				type: "DISPOSITIVO_LISTADO",
				payload: dispositivos,
			});
		} catch (e) {
			console.log(e);
			setErroAPI(e);
		}
	};
}

export function alterarImagemDispositivo(imagem) {
	return async (dispatch, getState) => {
		const id = (getState().dispositivo.dispositivoSelecionado || {}).id;
		if (!id) {
			setError("Selecione um dispositivo para poder alterar a imagem");
			return {};
		}
		try {
			await axios.put(`${consts.API_URL}/dispositivo/imagem`, { imagem, id });
			const id_usuario = getState().auth.usuario.id;
			await dispatch(getLista(id_usuario));
			setSuccess("Imagem alterada com sucesso");
		} catch (e) {
			setErroAPI(e);
		}
	};
}
export function getListaDependentes() {
	return async (dispatch, getState) => {
		await axios
			.get(`${consts.API_URL}/dispositivoDependente`)
			.then((resp) => {
				dispatch({
					type: "SET_DEPENDENTES_DISPOSITIVO",
					payload: resp,
				});
			})
			.catch((e) => {
				setErroAPI(e);
			});
	};
}

export function setDispositivoSelecionado(dispositivo) {
	return {
		type: "DISPOSITIVO_SELECIONADO",
		payload: dispositivo,
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		if (!registro.id) {
			axios
				.post(`${consts.API_URL}/dispositivo`, {
					...registro,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista(registro.id_usuario_administrador));
					dispatch(setModoTela("cadastro"));
					dispatch(
						initForm({
							...registro,
							...resp.data,
						})
					);
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			try {
				//busca a lista de dependentes
				const listaDependentes = (await axios.get(`${consts.API_URL}/dispositivoDependente`)).data;

				if (registro.id_dispositivo_pai) {
					const paiJaPossuiPai = listaDependentes.find((item) => item.id_dispositivo_filho === registro.id_dispositivo_pai);
					if (paiJaPossuiPai) {
						setError("O dispositivo selecionado não pode ser pai, pois já é filho de outro dispositivo");
						return {};
					}
				}

				await axios.put(`${consts.API_URL}/dispositivo`, registro);

				const registrosParaApagar = listaDependentes.filter((item) => item.id_dispositivo_filho === registro.id);
				//apagar todos registros onde id_dispositivo_filho === registro.id
				for (let i = 0; i < registrosParaApagar.length; i++) {
					await axios.delete(
						`${consts.API_URL}/dispositivoDependente?id_dispositivo_pai=${registrosParaApagar[i].id_dispositivo_pai}&id_dispositivo_filho=${registrosParaApagar[i].id_dispositivo_filho}`
					);
				}
				//se tiver pai cria um novo registro vinculando pai e filho
				if (registro.id_dispositivo_pai) {
					registro.id_dispositivo_filho = registro.id;
					await axios.post(`${consts.API_URL}/dispositivoDependente`, registro);

					await atualizarFilho(getState().dispositivo.lista, registro.id_dispositivo_pai, registro.id_dispositivo_filho);
				}
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista(registro.id_usuario));
				dispatch(setModoTela("lista"));
			} catch (e) {
				console.log(e);
				setErroAPI(e);
			}
		}
	};
}

export function excluir(registro) {
	return async (dispatch, getState) => {
		//se tiver filhos não deixa apagar
		if (registro.dispositivos_filhos.length) {
			setError("O dispositivo possui filhos, remova o vínculo de pai no cadastro dos dispositivos filhos antes de tentar novamente");
			return {};
		}

		//se não tiver filhos:
		//remove o vínculo da tabela de dependentes, apagando todos
		//registros onde o item atual constar como id_dispositivo_filho
		const listaDependentes = (await axios.get(`${consts.API_URL}/dispositivoDependente`)).data;
		const registrosParaApagar = listaDependentes.filter((item) => item.id_dispositivo_filho === registro.id);
		//apagar todos registros onde id_dispositivo_filho === registro.id
		for (let i = 0; i < registrosParaApagar.length; i++) {
			await axios.delete(
				`${consts.API_URL}/dispositivoDependente?id_dispositivo_pai=${registrosParaApagar[i].id_dispositivo_pai}&id_dispositivo_filho=${registrosParaApagar[i].id_dispositivo_filho}`
			);
		}

		if (!getState().auth.usuario.administrador) {
			axios
				.put(`${consts.API_URL}/dispositivo`, {
					...registro,
					id_usuario: null,
					nome: null,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(setDispositivoSelecionado(null));
					dispatch(getLista(registro.id_usuario));
					window.location.reload();
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			axios
				.put(`${consts.API_URL}/dispositivo`, {
					...registro,
					id_usuario_administrador: null,
					nome_administrador: null,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(setDispositivoSelecionado(null));
					dispatch(getLista(registro.id_usuario));
					window.location.reload();
				})
				.catch((e) => {
					setErroAPI(e);
				});
		}
	};
}

//quanto define um pai, atualiza todos os dados do filho
async function atualizarFilho(dispositivos, id_dispositivo_pai, id_dispositivo_filho) {
	//pega o nr serie do pai e do filho
	let nrSeriePai = null;
	let nrSerieFilho = null;

	dispositivos.map((item) => {
		if (item.id === id_dispositivo_pai) {
			nrSeriePai = item.serie;
		}
		if (item.id === id_dispositivo_filho) {
			nrSerieFilho = item.serie;
		}
	});

	//atualiza a dataHora
	const tresHorasEmMs = 3 * 60 * 60 * 1000;
	const dataHoraPai = (await axios.get(`${consts.API_URL}/aquario/datahora?serie=${nrSeriePai}`)).data.datahora * 1000 + tresHorasEmMs; //transforma em ms

	await axios.post(`${consts.API_URL}/aquario/datahora?serie=${nrSerieFilho}`, {
		dataHora: DateFormat.getTimestampParaDataHora(dataHoraPai),
	});

	//atualiza amanhecerAnoitecer
	const amanhecerAnoitecerPai = (await axios.get(`${consts.API_URL}/aquario/amanhecerAnoitecer?serie=${nrSeriePai}`)).data;

	const amanhecerAnoitecerPaiComHorarios = adicionarHorariosAosValores(amanhecerAnoitecerPai);

	await axios.post(`${consts.API_URL}/aquario/amanhecerAnoitecer?serie=${nrSerieFilho}`, amanhecerAnoitecerPaiComHorarios);

	//atualiza controleManual
	const controleManualPai = (await axios.get(`${consts.API_URL}/aquario/controleManual?serie=${nrSeriePai}`)).data;

	await axios.post(`${consts.API_URL}/aquario/controleManual?serie=${nrSerieFilho}`, controleManualPai);

	//atualiza lunar
	const lunarPai = (await axios.get(`${consts.API_URL}/aquario/lunar?serie=${nrSeriePai}`)).data;

	const registroLunar = {
		brancoLuaNova: lunarPai.bNova,
		brancoLuaCheia: lunarPai.bCheia,
		azulLuaNova: lunarPai.aNova,
		azulLuaCheia: lunarPai.aCheia,
		azulRoyalLuaNova: lunarPai.aRNova,
		azulRoyalLuaCheia: lunarPai.aRCheia,
		uvLuaNova: lunarPai.uvNova,
		uvLuaCheia: lunarPai.uvCheia,
		horarioInicial: lunarPai.inicio,
		horarioFinal: lunarPai.fim,
		desativar: lunarPai.desativar,
	};

	if (lunarPai.eNova) registroLunar.extraLuaNova = 0;
	if (lunarPai.eCheia) registroLunar.extraLuaCheia = 0;

	await axios.post(`${consts.API_URL}/aquario/lunar?serie=${nrSerieFilho}`, registroLunar);

	//atualiza clima
	const climaPai = (await axios.get(`${consts.API_URL}/aquario/clima?serie=${nrSeriePai}`)).data;

	const registroClima = {
		branco: climaPai.nBranco,
		azul: climaPai.nAzul,
		azulRoyal: climaPai.nAzulRoyal,
		uv: climaPai.nUV,
		extra: climaPai.nExtra !== undefined ? climaPai.nExtra : undefined,
		intervaloDias: climaPai.dias,
		probabilidadeNuvem: climaPai.nProbabilidade,
		probabilidadeRelampago: climaPai.rProbabilidade,
		quantidadeMinMaxDia: [climaPai.quantidadeMin, climaPai.quantidadeMax],
		duracaoMinMaxDia: [climaPai.duracaoMin, climaPai.duracaoMax],
	};

	await axios.post(`${consts.API_URL}/aquario/clima?serie=${nrSerieFilho}`, registroClima);
}

//adiciona o horario ao valor, para enviar uma lista de objetos
//com horario e valor para a rota amanhecerAnoitecer
function adicionarHorariosAosValores({ branco, azul, azulRoyal, uv, extra }) {
	const d = new Date();
	const trintaMin = 1000 * 60 * 30;
	d.setHours(0, 0, 0, 0);

	const [canalBranco, canalAzul, canalAzulRoyal, canalUV, canalExtra] = [[], [], [], [], []];

	for (let i = 0; i < 48; i++) {
		const data = new Date(d.getTime() + trintaMin * i);
		const horario = data.toLocaleTimeString("pt-BR", {
			timeStyle: "short",
		});

		canalBranco.push({ horario, valor: branco[i] });
		canalAzul.push({ horario, valor: azul[i] });
		canalAzulRoyal.push({ horario, valor: azulRoyal[i] });
		canalUV.push({ horario, valor: uv[i] });
		canalExtra.push({ horario, valor: 0 });
	}

	const registro = {
		branco: [...canalBranco],
		azul: [...canalAzul],
		azulRoyal: [...canalAzulRoyal],
		uv: [...canalUV],
	};
	if (extra) {
		registro.extra = [...canalExtra];
	}
	return registro;
}
