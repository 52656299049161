import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { sleep } from "../utils/sleep";

export function getDados() {
	return async (dispatch, getState) => {
		dispatch({ type: "RESETAR_AMANHECER_ANOITECER" });

		if (getState().controleManual.ativado) {
			await sleep(3000);
		}

		if (getState().dispositivo.dispositivoSelecionado) {
			await axios
				.get(`${consts.API_URL}/aquario/amanhecerAnoitecer?serie=${getState().dispositivo.dispositivoSelecionado.serie}`)
				.then((resp) => {
					dispatch({
						type: "AMANHECER_ANOITECER_LISTADO",
						payload: resp,
					});
				})
				.catch((e) => {
					console.log(e);
					setError("Controladora não respondeu");
				});
		}
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		const canais = {};
		canais.branco = registro.branco;
		canais.azul = registro.azul;
		canais.azulRoyal = registro.azulRoyal;
		canais.uv = registro.uv;
		if (getState().amanhecerAnoitecer.registro.extra) {
			canais.extra = registro.extra;
		}

		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado;

		if (!dispositivoSelecionado) {
			setError("Dispositivo não está disponível");
			return {};
		}

		//agrupa o número de série de todos dispositivos que devem ser atualizados
		const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map((item) => item.serie);
		listaDeDispositivos.push(dispositivoSelecionado.serie);
		//atualiza cada um dos dispositivos
		try {
			for (let i = 0; i < listaDeDispositivos.length; i++) {
				await axios.post(`${consts.API_URL}/aquario/amanhecerAnoitecer?serie=${listaDeDispositivos[i]}`, canais);
			}
		} catch (e) {
			console.log(e);
			setErroAPI(e);
		}

		dispatch(getDados());
		setSuccess("Amanhecer/Anoitecer atualizado com sucesso");
	};
}
