import React from "react";
import { Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";

export const CustomButton = (props) => {
	const CustomButton = styled(Button)(({ theme }) => ({
		color: props.color,
		border: `1px solid ${props.color}`,
		backgroundColor: "",
		"&:hover": {
			backgroundColor: props.color,
			color: "#FFFFFF",
		},
	}));

	return (
		<CustomButton fullWidth={props.fullWidth} variant={props.variant} disabled={props.disabled} onClick={props.onClick} href={props.href}>
			{props.children}
		</CustomButton>
	);
};
