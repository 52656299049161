const INITIAL_STATE = {
	registro: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "AMANHECER_ANOITECER_LISTADO":
			return {
				...state,
				registro: action.payload.data,
			};

		case "RESETAR_AMANHECER_ANOITECER":
			return INITIAL_STATE;

		default:
			return state;
	}
};
