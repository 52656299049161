import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import Select from "../common/form/select";

import { setModoTela, initForm, getLista, getListaDependentes } from "./dispositivoActions";

class DispositivoForm extends Component {
	async componentDidMount() {
		await this.props.getLista(this.props.sessao.id || 0);
		this.props.initForm(this.props.registro);
	}

	async componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.registro) !== JSON.stringify(this.props.registro)) {
			await this.props.getLista(this.props.sessao.id || 0);
			this.props.initForm(this.props.registro);
		}
	}

	render() {
		if (!this.props.registro.id) {
			window.location = "#/";
		}
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Field name="tipo_nome" component={LabelAndInput} label="Tipo" placeholder="Informe o Tipo" cols="12 12 12 12" />

						<Field name="serie" component={LabelAndInput} label="Série" placeholder="Informe a Série" cols="12 12 12 12" />
						<Field name="nome" component={LabelAndInput} label="Tipo" placeholder="Informe o Tipo" cols="12 12 12 12" />
						<Select
							options={this.props.lista
								.filter((item) => item.id !== this.props.registro.id && !item.id_dispositivo_pai)
								.map((item) => ({
									id: item.id,
									valor: item.nome,
								}))}
							label="Dispositivo Pai"
							placeholder={this.props.formularioValues && !this.props.formularioValues.id_dispositivo_pai ? "Selecione um dispositivo" : null}
							cols="12 12 12 12"
							disabled={
								this.props.formularioValues &&
								this.props.formularioValues.dispositivos_filhos &&
								this.props.formularioValues.dispositivos_filhos.length
							}
							onChange={(e) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_dispositivo_pai: e,
								});
							}}
							value={this.props.formularioValues && this.props.formularioValues.id_dispositivo_pai}
						/>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols="6 6 4 3">
								{/* {this.props.excluir ? (
                  <Button
                    text="Excluir"
                    submit
                    type={"danger"}
                    icon={"fa fa-trash"}
                  />
                ) : ( */}
								<Button
									text="Salvar"
									submit
									// disabled={true}
									type={"success"}
									icon={"fa fa-check"}
								/>
								{/* )} */}
							</Grid>
							<Grid cols="6 6 4 3">
								<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => (window.location = "#/")} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

DispositivoForm = reduxForm({
	form: "dispositivoForm",
	destroyOnUnmount: false,
})(DispositivoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.dispositivo.registro,
	lista: state.dispositivo.lista,
	formularioValues: getFormValues("dispositivoForm")(state),
	// dispositivo_pai: state.dispositivo.dispositivosDependentes.find(
	//   (item) => item.id_dispositivo_filho === state.dispositivo.registro.id
	// ),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, getLista, getListaDependentes }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoForm);
