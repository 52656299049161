import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";
import { sleep } from "../utils/sleep";

export function getDados() {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_CONTROLE_MANUAL_CARREGANDO", payload: true });
    if (getState().dispositivo.dispositivoSelecionado) {
      await axios
        .get(
          `${consts.API_URL}/aquario/controleManual?serie=${
            getState().dispositivo.dispositivoSelecionado.serie
          }`
        )
        .then((resp) => {
          dispatch({
            type: "CONTROLE_MANUAL_LISTADO",
            payload: resp,
          });
        })
        .catch((e) => {
          console.log(e);
          setError("Controladora não respondeu");
        })
        .finally(() =>
          dispatch({ type: "SET_CONTROLE_MANUAL_CARREGANDO", payload: false })
        );
    }
  };
}
//dispara quando clica no item do menu
export function setControleManualAtivado(status) {
  return async (dispatch, getState) => {
    if (!status) {
      await sleep(2000);
    }
    dispatch({
      type: "SET_CONTROLE_MANUAL_ATIVADO",
      payload: !!status,
    });
  };
}

export function resetState() {
  return (dispatch, getState) => {
    dispatch({
      type: "DESATIVAR_CONTROLE_MANUAL",
    });
  };
}

export function salvar(registro) {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_CONTROLE_MANUAL_CARREGANDO", payload: true });
    if (registro.extra >= 0) registro.extra = 0;

    const dispositivoSelecionado =
      getState().dispositivo.dispositivoSelecionado;

    if (!dispositivoSelecionado) {
      setError("Dispositivo não está disponível");
      return {};
    }

    //agrupa o número de série de todos dispositivos que devem ser atualizados
    const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map(
      (item) => item.serie
    );
    listaDeDispositivos.push(dispositivoSelecionado.serie);

    //atualiza cada um dos dispositivos
    try {
      for (let i = 0; i < listaDeDispositivos.length; i++) {
        await axios.post(
          `${consts.API_URL}/aquario/controleManual?serie=${listaDeDispositivos[i]}`,
          registro
        );
      }
      setSuccess("Controle manual ativado com sucesso");
    } catch (e) {
      console.log(e);
      setErroAPI(e);
    } finally {
      dispatch({ type: "SET_CONTROLE_MANUAL_CARREGANDO", payload: false });
    }

    // dispatch(getDados());
  };
}

export function desativarControleManual() {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_CONTROLE_MANUAL_CARREGANDO", payload: true });
    const dispositivoSelecionado =
      getState().dispositivo.dispositivoSelecionado;

    if (!dispositivoSelecionado) {
      setError("Dispositivo não está disponível");
      return {};
    }
    //agrupa o número de série de todos dispositivos que devem ser atualizados
    const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map(
      (item) => item.serie
    );
    listaDeDispositivos.push(dispositivoSelecionado.serie);

    //atualiza cada um dos dispositivos
    try {
      for (let i = 0; i < listaDeDispositivos.length; i++) {
        const resp = await axios.delete(
          `${consts.API_URL}/aquario/controleManual?serie=${listaDeDispositivos[i]}`
        );
      }
    } catch (e) {
      console.log(e);
      setErroAPI(e);
    } finally {
      dispatch({ type: "SET_CONTROLE_MANUAL_CARREGANDO", payload: false });
    }

    setSuccess("Controle manual desativado com sucesso");
  };
}
