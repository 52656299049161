import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";
import { sleep } from "../utils/sleep";

export function initForm(registro = {}) {
  return {
    type: "DATAHORA_SET_REGISTRO",
    payload: {
      registro: registro,
    },
  };
}

export function getDados() {
  return async (dispatch, getState) => {
    dispatch({ type: "RESETAR_DATAHORA" });
    if (getState().controleManual.ativado) {
      await sleep(3000);
    }

    if (getState().dispositivo.dispositivoSelecionado) {
      axios
        .get(
          `${consts.API_URL}/aquario/datahora?serie=${
            getState().dispositivo.dispositivoSelecionado.serie
          }`
        )
        .then((resp) => {
          dispatch({
            type: "DATAHORA_LISTADO",
            payload: resp,
          });
        })
        .catch((e) => {
          console.log(e);
          setError("Controladora não respondeu");
        });
    }
  };
  /*
	{datahora: 1652369857}
*/
}

export function salvar(registro) {
  return async (dispatch, getState) => {
    const dispositivoSelecionado =
      getState().dispositivo.dispositivoSelecionado;

    if (!dispositivoSelecionado) {
      setError("Dispositivo não está disponível");
      return {};
    }

    //agrupa o número de série de todos dispositivos que devem ser atualizados
    const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map(
      (item) => item.serie
    );
    listaDeDispositivos.push(dispositivoSelecionado.serie);

    console.log(registro);
    //atualiza cada um dos dispositivos
    try {
      for (let i = 0; i < listaDeDispositivos.length; i++) {
        await axios.post(
          `${consts.API_URL}/aquario/datahora?serie=${listaDeDispositivos[i]}`,
          {
            dataHora:
              registro.dataHora && registro.dataHora.ts
                ? DateFormat.getTimestampParaDataHora(registro.dataHora.ts)
                : registro.dataHora,
          }
        );
      }
    } catch (e) {
      console.log(e);
      setErroAPI(e);
    }

    dispatch(getDados());
    setSuccess("Data/Hora atualizada com sucesso");
  };
}
