import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BottomNavigation, BottomNavigationAction as MuiBottomNavigationAction } from "@mui/material";
import { styled } from "@mui/material/styles";

import { setControleManualAtivado } from "../../controleManual/controleManualActions";

import "./bottomMenuBar.css";
import { Dashboard as DashboardIcon, WatchLater as WatchLaterIcon } from "@material-ui/icons";

import { Swipe as SwipeIcon, Brightness4 as Brightness4Icon, DarkMode as DarkModeIcon, Cloud as CloudIcon } from "@mui/icons-material";

const BottomNavigationAction = styled(MuiBottomNavigationAction)({
	color: "#4db6ac",
	"&.Mui-selected": {
		color: "primary",
	},
});

class BottomMenuBar extends Component {
	render() {
		return (
			<BottomNavigation
				showLabels
				style={{
					display: "flex",
					alignItems: "start",
					justifyContent: window.innerWidth <= 600 ? "start" : "center",
					position: "fixed",
					paddingTop: 7,
					flexWrap: "no-wrap",
					right: 0,
					bottom: 0,
					left: 0,
					clear: "both",
					flexShrink: 0,
					minHeight: window.innerWidth <= 940 ? 90 : 70,
					width: "100%",
					overflowX: "scroll",
					boxShadow: "#000 2px 2px 5px",
					zIndex: 9999,
				}}
				value={this.props.pathname}
				className="MuiPaper-elevation4  bottom-menu-sem-scrollbar"
			>
				<BottomNavigationAction
					href="#/"
					label="Dashboard"
					value="#/"
					icon={<DashboardIcon />}
					style={{
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.props.setControleManualAtivado(false)}
				/>

				<BottomNavigationAction
					href="#/datahora"
					label="Data/Hora"
					value="#/datahora"
					icon={<WatchLaterIcon />}
					style={{
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.props.setControleManualAtivado(false)}
				/>

				<BottomNavigationAction
					href="#/amanhecerAnoitecer"
					label={window.innerWidth <= 1480 ? "Amanhecer/ Anoitecer" : "Amanhecer/Anoitecer"}
					value="#/amanhecerAnoitecer"
					icon={<Brightness4Icon />}
					style={{
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.props.setControleManualAtivado(false)}
				/>
				<BottomNavigationAction
					href="#/controleManual"
					label="Controle Manual"
					value="#/controleManual"
					icon={<SwipeIcon />}
					style={{
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.props.setControleManualAtivado(true)}
				/>
				<BottomNavigationAction
					href="#/lunar"
					label="Lunar"
					value="#/lunar"
					icon={<DarkModeIcon />}
					style={{
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.props.setControleManualAtivado(false)}
				/>
				<BottomNavigationAction
					href="#/clima"
					label="Clima"
					value="#/clima"
					icon={<CloudIcon />}
					style={{
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.props.setControleManualAtivado(false)}
				/>
			</BottomNavigation>
		);
	}
}
const mapStateToProps = (state) => ({
	pathname: window.location.hash,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setControleManualAtivado,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(BottomMenuBar);
