const INITIAL_STATE = {
  modoTela: "dashboard",
  registro: {
    wifi: "",
    tempo: "",
    dataHora: "",
    iluminacaoLua: "",
    tempoProximaNuvem: "",
    horarioProximaNuvem: "",
    relampago: "",
    temperaturaLuminaria: "",
    branco: "",
    azul: "",
    azulRoyal: "",
    uv: "",
    imagem_aquario: "",
  },
  // registro: {
  // tempo: "2 dias, 5 horas e 23 minutos",
  // dataHora: new Date().toLocaleString("pt-BR", {
  //   dateStyle: "short",
  //   timeStyle: "short",
  // }),
  // iluminacaoLua: 52,
  // nuvem: "Não haverá nuvens",
  // relampago: "Não haverá relâmpagos",
  // temperaturaLuminaria: 45,
  // branco: 25,
  // azul: 100,
  // azulRoyal: 50,
  // uv: 80,
  // imagem_aquario: imagemTeste,
  // }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DASHBOARD_MODO_TELA":
      return { ...state, modoTela: action.payload.modo };

    case "DASHBOARD_LISTADO":
      let data = action.payload.data;

      let registro = null;
      if (data) {
        registro = {};

        if (data.tempo) {
          var s = data.tempo;
          var d = Math.floor(s / (24 * 60 * 60));
          s -= d * (24 * 60 * 60);
          var h = Math.floor(s / (60 * 60));
          s -= h * (60 * 60);
          var m = Math.floor(s / 60);

          registro.tempo = `${d} ${d === 1 ? "dia" : "dias"}, ${h} ${
            h === 1 ? "hora" : "horas"
          } e ${m} ${m === 1 ? "minuto" : "minutos"}`;
        }
        if (data.datahora) {
          let datahoraTemporaria = new Date(data.datahora * 1000);
          datahoraTemporaria.setHours(
            datahoraTemporaria.getHours() +
              datahoraTemporaria.getTimezoneOffset() / 60
          );
          registro.dataHora = datahoraTemporaria.toLocaleString("pt-BR", {
            dateStyle: "short",
            timeStyle: "short",
          });
        } else {
          registro.dataHora = "";
        }
        registro.temperaturaLuminaria = data.temperatura
          ? parseFloat(data.temperatura).toFixed(1)
          : 0;

        if (data.temperatura == -1) {
          registro.temperaturaLuminaria = 28;
        }

        registro.temperaturaLuminaria += " °C";

        registro.iluminacaoLua = data.lua ? `${data.lua} %` : "";

        if (data.haveraNuvem && JSON.parse(data.horaNuvem).length) {
          registro.horaNuvem = JSON.parse(data.horaNuvem).sort((a, b) => a - b);

          const dataHoraAtual = new Date();
          const minutosAtuais =
            dataHoraAtual.getHours() * 60 + dataHoraAtual.getMinutes();
          registro.horaNuvem = registro.horaNuvem.find(
            (horario) => horario > minutosAtuais
          );
          if (registro.horaNuvem) {
            const hrNuvem = Math.floor(registro.horaNuvem / 60);
            const minNuvem = registro.horaNuvem - hrNuvem * 60;
            const textoHoraNuvem =
              ". Hora que irá ocorrer: " +
              (hrNuvem < 10 ? "0" : "") +
              hrNuvem +
              ":" +
              (minNuvem < 10 ? "0" : "") +
              minNuvem;
            const tempoParaOcorrer = registro.horaNuvem - minutosAtuais;
            const hrTempoParaOcorrer = Math.floor(tempoParaOcorrer / 60);
            const minTempoParaOcorrer =
              tempoParaOcorrer - hrTempoParaOcorrer * 60;
            const textoTempoParaOcorrer =
              "A próxima ocorrerá em " +
              (hrTempoParaOcorrer > 0
                ? hrTempoParaOcorrer +
                  (hrTempoParaOcorrer == 1 ? " hora" : " horas")
                : "") +
              (hrTempoParaOcorrer > 0 && minTempoParaOcorrer > 0 ? " e " : "") +
              (minTempoParaOcorrer > 0
                ? minTempoParaOcorrer +
                  (minTempoParaOcorrer == 1 ? " minuto" : " minutos")
                : "");
            registro.tempoProximaNuvem = textoTempoParaOcorrer;
            registro.horarioProximaNuvem = textoHoraNuvem;
          } else {
            registro.tempoProximaNuvem = "Não haverá nuvens";
          }
        } else {
          registro.tempoProximaNuvem = "Não haverá nuvens";
        }

        registro.relampago = data.haveraRelampago
          ? "Haverá relâmpagos"
          : "Não haverá relâmpagos";
        registro.branco = data.pBranco
          ? Math.round((data.pBranco * 100) / 1023)
          : 0;
        registro.azul = data.pAzul ? Math.round((data.pAzul * 100) / 1023) : 0;
        registro.azulRoyal = data.pAzulRoyal
          ? Math.round((data.pAzulRoyal * 100) / 1023)
          : 0;
        registro.uv = data.pUV ? Math.round((data.pUV * 100) / 1023) : 0;

        registro.wifi = data.RSSI
          ? data.RSSI < -70
            ? "Ruim"
            : data.RSSI <= -50
            ? "Bom"
            : "Excelente"
          : "";
      }

      return {
        ...state,
        registro,
      };
    case "RESETAR_DASHBOARD":
      return INITIAL_STATE;
    default:
      return state;
  }
}
