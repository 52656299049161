import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";
import { sleep } from "../utils/sleep";
export function getDados() {
  return async (dispatch, getState) => {
    dispatch({ type: "RESETAR_LUNAR" });
    if (getState().controleManual.ativado) {
      await sleep(3000);
    }

    if (getState().dispositivo.dispositivoSelecionado) {
      axios
        .get(
          `${consts.API_URL}/aquario/lunar?serie=${
            getState().dispositivo.dispositivoSelecionado.serie
          }`
        )
        .then((resp) => {
          dispatch({
            type: "LUNAR_LISTADO",
            payload: resp,
          });
        })
        .catch((e) => {
          console.log(e);
          setError("Controladora não respondeu");
        });
    }
  };
}

export function salvar(registro) {
  return async (dispatch, getState) => {
    // if (registro.extraLuaNova >= 0) registro.extraLuaNova = 0;
    // if (registro.extraLuaCheia >= 0) registro.extraLuaCheia = 0;
    const dispositivoSelecionado =
      getState().dispositivo.dispositivoSelecionado;

    if (!dispositivoSelecionado) {
      setError("Dispositivo não está disponível");
      return {};
    }

    //agrupa o número de série de todos dispositivos que devem ser atualizados
    const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map(
      (item) => item.serie
    );
    listaDeDispositivos.push(dispositivoSelecionado.serie);

    const dispositivosAtualizadosSucesso = [];
    const dispositivosAtualizadosErro = [];

    //atualiza cada um dos dispositivos
    for (let i = 0; i < listaDeDispositivos.length; i++) {
      try {
        await axios.post(
          `${consts.API_URL}/aquario/lunar?serie=${listaDeDispositivos[i]}`,
          registro
        );

        dispositivosAtualizadosSucesso.push(listaDeDispositivos[i]);
      } catch (e) {
        dispositivosAtualizadosErro.push(listaDeDispositivos[i]);
      }
    }
    if (dispositivosAtualizadosSucesso.length > 0) {
      const plural = dispositivosAtualizadosSucesso.length > 1 ? "s" : "";
      setSuccess(
        `Efeito lunar atualizado com sucesso no${plural} dispositivo${plural} ${dispositivosAtualizadosSucesso
          .toString()
          .replace(/,/g, ", ")}`
      );
    }

    if (dispositivosAtualizadosErro.length > 0) {
      const plural = dispositivosAtualizadosErro.length > 1 ? "s" : "";
      setError(
        `Não foi possível atualizar o efeito lunar do${plural} dispositivo${plural} ${dispositivosAtualizadosErro
          .toString()
          .replace(/,/g, ", ")}`
      );
    }
    // dispatch(getDados());
  };
}
