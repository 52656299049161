import React from "react";

export default (props) => (
	<div
		className={`${props.responsive ? "table-responsive" : ""} ${props.striped ? "table-striped" : ""}`}
		style={{ display: "flex", overflowX: "auto" }}
	>
		<table className="table table-hover">{props.children}</table>
	</div>
);
