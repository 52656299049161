import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const CustomSwitch = styled(Switch)((props) => {
	const { theme, customColor = "#4db6ac" } = props;

	return {
		"& .MuiSwitch-switchBase.Mui-checked": {
			color: customColor,
			"&:hover": {
				backgroundColor: alpha(customColor, theme.palette.action.hoverOpacity),
			},
		},
		"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
			backgroundColor: customColor,
		},
	};
});
