import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";
import { sleep } from "../utils/sleep";
export function getDados() {
	return async (dispatch, getState) => {
		dispatch({ type: "RESETAR_CLIMA" });
		if (getState().controleManual.ativado) {
			await sleep(3000);
		}

		if (getState().dispositivo.dispositivoSelecionado) {
			await axios
				.get(`${consts.API_URL}/aquario/clima?serie=${getState().dispositivo.dispositivoSelecionado.serie}`)
				.then((resp) => {
					dispatch({
						type: "CLIMA_LISTADO",
						payload: resp,
					});
				})
				.catch((e) => {
					console.log(e);
					setError("Controladora não respondeu");
				});
		}
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado;

		if (!dispositivoSelecionado) {
			setError("Dispositivo não está disponível");
			return {};
		}

		//agrupa o número de série de todos dispositivos que devem ser atualizados
		const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map((item) => item.serie);

		listaDeDispositivos.push(dispositivoSelecionado.serie);

		//atualiza cada um dos dispositivos
		try {
			for (let i = 0; i < listaDeDispositivos.length; i++) {
				await axios.post(`${consts.API_URL}/aquario/clima?serie=${listaDeDispositivos[i]}`, registro);
			}
		} catch (e) {
			console.log(e);
			setErroAPI(e);
		}

		dispatch(getDados());
		setSuccess("Efeito clima atualizado com sucesso");
	};
}
export function executarAgora() {
	return async (dispatch, getState) => {
		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado;

		if (!dispositivoSelecionado) {
			setError("Dispositivo não está disponível");
			return {};
		}

		//agrupa o número de série de todos dispositivos que devem ser atualizados
		const listaDeDispositivos = dispositivoSelecionado.dispositivos_filhos.map((item) => item.serie);

		listaDeDispositivos.push(dispositivoSelecionado.serie);

		//atualiza cada um dos dispositivos
		try {
			for (let i = 0; i < listaDeDispositivos.length; i++) {
				await axios.put(`${consts.API_URL}/aquario/clima?serie=${listaDeDispositivos[i]}`);
			}
		} catch (e) {
			console.log(e);
			setErroAPI(e);
		}

		setSuccess("Efeito clima executado com sucesso");
	};
}
