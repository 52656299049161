import React from "react";
import { Avatar, Grid, Typography, Card, CardContent } from "@material-ui/core";

export default (props) => {
	let color = "#0073b6";
	let colorDark = "#015c93";
	let colorLight = "";

	switch (props.color) {
		case "blue":
			color = "#0073B6";
			colorDark = "#015C93";
			break;

		case "lightblue":
			color = "#0097ed";
			colorDark = "#0073b6";
			break;

		case "purple":
			color = "#605ca8";
			colorDark = "#4e4a85";
			colorLight = "#f6ebff";
			break;

		case "orange":
			color = "#ff7802";
			colorDark = "#e56a02";
			break;

		case "green":
			color = "#0097a7";
			colorDark = "#00838f";
			break;

		case "lightGreen":
			color = "#4db6ac";
			colorDark = "#00867d";
			break;

		case "darkGreen":
			color = "#009688";
			colorDark = "#00675b";
			colorLight = "#e0f2f1";
			break;

		case "yellow":
			color = "#ffab00";
			colorDark = "#ff8f00";
			break;

		case "red":
			color = "#ef5350";
			colorDark = "#b61827";
			break;

		default:
	}

	let style = {
		cardContent: {
			display: "flex",
			flexDirection: "row",
			padding: 0,
			minHeight: 80,
			background: color,
		},
		contentIcon: {
			display: "flex",
			flexDirection: "column",
			background: colorDark,
			justifyContent: "center",
			padding: 2,
		},
		contentTitle: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			paddingLeft: 4,
			paddingTop: 12,
			paddingRight: 4,
			paddingBottom: 4,
		},
		title: {
			color: "#fff",
			fontWeight: "bold",
			fontSize: 13,
		},
		subtitle: {
			color: "#fff",
			fontSize: 13,
		},
		icon: {
			color: "#fff",
			backgroundColor: colorDark,
		},
		contentComponent: {
			background: colorLight,
			justifyContent: "center",
			height: props.height,
			padding: 0,
			paddingTop: props.paddingtop || 0,
		},
	};

	return (
		<Grid item xs={props.xs} xl={props.xl} sm={props.sm} md={props.md} lg={props.lg}>
			<Card style={{ boxShadow: "#aaa 2px 2px 5px" }}>
				<CardContent style={style.cardContent}>
					<div style={style.contentIcon}>
						<Avatar variant="square" style={style.icon}>
							{props.icon}
						</Avatar>
					</div>
					<div style={style.contentTitle}>
						<Typography gutterTop style={style.title}>
							{props.title}
						</Typography>
						<Typography gutterBottom style={style.subtitle}>
							{props.subtitle}
						</Typography>
					</div>
				</CardContent>
				{props.component ? <CardContent style={style.contentComponent}>{props.component}</CardContent> : null}
			</Card>
		</Grid>
	);
};
