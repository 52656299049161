import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import AuthReducer from "../auth/authReducer";
import DashboardReducer from "../dashboard/dashboardReducer";
import TabReducer from "../common/tab/tabReducer";
import UsuarioReducer from "../usuario/usuarioReducer";
import DispositivoReducer from "../dispositivo/dispositivoReducer";
import DataHoraReducer from "../dataHora/dataHoraReducer";
import AmanhecerAnoitecerReducer from "../amanhecerAnoitecer/amanhecerAnoitecerReducer";
import LunarReducer from "../lunar/lunarReducer";
import ClimaReducer from "../clima/climaReducer";
import ControleManualReducer from "../controleManual/controleManualReducer";
import DispositivoUsuarioReducer from "../dispositivoUsuario/dispositivoUsuarioReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  dashboard: DashboardReducer,
  usuario: UsuarioReducer,
  dispositivo: DispositivoReducer,
  dataHora: DataHoraReducer,
  amanhecerAnoitecer: AmanhecerAnoitecerReducer,
  lunar: LunarReducer,
  clima: ClimaReducer,
  controleManual: ControleManualReducer,
  dispositivoUsuario: DispositivoUsuarioReducer,
  tab: TabReducer,
  form: formReducer,
  toastr: toastrReducer,
});

export default rootReducer;
