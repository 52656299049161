const INITIAL_STATE = {
  ativado: false,
  carregando: false,
  registro: { branco: 0, azul: 0, azulRoyal: 0, uv: 0 },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CONTROLE_MANUAL_ATIVADO":
      return {
        ...state,
        ativado: action.payload,
      };
    case "SET_CONTROLE_MANUAL_CARREGANDO":
      return {
        ...state,
        carregando: action.payload,
      };
    case "DESATIVAR_CONTROLE_MANUAL":
      return INITIAL_STATE;

    case "CONTROLE_MANUAL_LISTADO":
      const registro = {};
      registro.branco = action.payload.data.branco;
      registro.azul = action.payload.data.azul;
      registro.azulRoyal = action.payload.data.azulRoyal;
      registro.uv = action.payload.data.uv;
      if (action.payload.data.extra >= 0) {
        registro.extra = 0;
      }

      return {
        ...state,
        carregando: false,
        registro,
      };

    default:
      return state;
  }
};
