import React from "react";
import { Switch, Route, Redirect } from "react-router";

import Dashboard from "../dashboard/dashboard";
import DataHoraForm from "../dataHora/dataHoraForm";
import ControleManualForm from "../controleManual/controleManualForm";
import LunarForm from "../lunar/lunarForm";
import ClimaForm from "../clima/climaForm";
import AmanhecerAnoitecerForm from "../amanhecerAnoitecer/amanhecerAnoitecerForm";
import UsuarioAlterarSenha from "../usuario/usuarioAlterarSenha";
import Dispositivo from "../dispositivo/dispositivo";
import DispositivoUsuarioNovo from "../dispositivoUsuario/dispositivoUsuarioNovo";

export default () => (
	<div style={{ paddingTop: 64 }}>
		<Switch>
			<Route exact path="/" component={Dashboard} />
			<Route path="/datahora" component={DataHoraForm} />
			<Route path="/amanhecerAnoitecer" component={AmanhecerAnoitecerForm} />
			<Route path="/controleManual" component={ControleManualForm} />
			<Route path="/lunar" component={LunarForm} />
			<Route path="/clima" component={ClimaForm} />
			<Route path="/dispositivoUsuarioNovo" component={DispositivoUsuarioNovo} />
			<Route path="/dispositivo" component={Dispositivo} />
			<Route path="/usuarioAlterarSenha" component={UsuarioAlterarSenha} />
			<Redirect from="*" to="/" />
		</Switch>
	</div>
);
