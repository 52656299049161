import React from "react";

export default (props) => (
  <td
    {...props}
    align={props.alignRight ? "right" : props.alignCenter ? "center" : "left"}
    onClick={props.onClick}
    style={{
      ...props.style,
      padding: props.padding || 2,
      verticalAlign: "middle",
      minWidth: props.minWidth,
    }}
  >
    {props.children}
  </td>
);
