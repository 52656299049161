import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { CustomButton } from "../common/components/CustomButton";
import { setModoTela } from "../dashboard/dashboardActions";
import { getDados, salvar, resetState, desativarControleManual } from "./controleManualActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
class ControleManualForm extends Component {
	state = { branco: 0, azul: 0, azulRoyal: 0, uv: 0, executou: false };

	async componentDidMount() {
		await this.props.getListaDispositivo(this.props.sessao.id || 0);
		if (!this.state.executou) {
			await this.props.getDados();
			this.setState({ ...this.props.registro, executou: true });
		}
	}
	async componentDidUpdate(prevProps) {
		if (
			JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
			prevProps.dispositivoSelecionado !== null
		) {
			await this.props.getDados();
			this.setState({ ...this.props.registro, executou: true });
		}
	}
	async componentWillUnmount() {
		if (!this.desabilitarBotao() && !(this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.id_dispositivo_pai)) {
			await this.props.desativarControleManual();
		}
		await this.props.resetState();
	}
	getLocalStyles(cor) {
		const coresCanal = {
			branco: "#FFFFFF",
			azul: "#0055ff",
			azulRoyal: "#4169e1",
			uv: "#E164FE",
		};

		return {
			root: {
				padding: 10,
				paddingBottom: 100,
			},
			gridButton: {
				marginTop: 20,
			},
			sliderHorizontal: {
				"& .MuiSlider-thumb": {
					color: coresCanal[cor],
					height: 30,
					width: 30,
				},
				"& .MuiSlider-track": {
					color: coresCanal[cor],
					height: 15,
				},
				"& .MuiSlider-rail": {
					color: coresCanal[cor],
					height: 15,
				},
			},
		};
	}

	desabilitarBotao() {
		return JSON.stringify(this.props.registro) === JSON.stringify(this.state) || this.props.carregando;
	}

	render() {
		const style = this.getLocalStyles;
		const modoLeitura = !!(this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.id_dispositivo_pai);
		return (
			<Grid
				container
				spacing={1}
				columns={12}
				style={{
					paddingInline: "5%",
				}}
			>
				<div
					justifyContent="start"
					style={{
						paddingTop: 60,
						paddingBottom: 20,
						color: "#4db6ac",
					}}
				>
					<h4>
						<i className="fa fa-cog" style={{ marginLeft: 20, marginRight: 10 }}></i>
						Controle Manual {modoLeitura && "(Modo Leitura)"}
					</h4>
				</div>
				<Grid
					container
					style={{
						padding: 10,
						marginBottom: 20,
						borderRadius: 10,
					}}
				>
					<Grid
						item
						xs={12}
						md={2}
						style={{
							padding: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							style={{
								width: "96%",
								textAlign: "center",
								marginTop: 10,
								backgroundColor: "#ffffff",
								borderRadius: 8,
								paddingBlock: 15,
								color: "#000000",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Branco
						</div>
					</Grid>
					<Grid
						item
						xs={11}
						md={9}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 37,
							marginBottom: window.innerWidth <= 960 ? 37 : 0,
							paddingLeft: 30,
							justifyContent: "center",
						}}
					>
						<Slider
							sx={style("branco").sliderHorizontal}
							aria-labelledby="branco"
							style={{ marginRight: 5, backgroundColor: "#EEEEEE" }}
							valueLabelDisplay="auto"
							min={0}
							max={1023}
							disabled={modoLeitura || this.props.carregando}
							value={this.state.branco}
							onChange={(e, val) => {
								this.setState({ ...this.state, branco: val });
							}}
							onChangeCommitted={(e, val) => {
								this.props.salvar({ ...this.state, branco: val });
							}}
						/>
					</Grid>
					<Grid
						item
						xs={1}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 40,
							textAlign: "center",
						}}
					>
						{Math.floor((this.state.branco * 100) / 1023) + " %"}
					</Grid>
					<Grid
						item
						xs={12}
						md={2}
						style={{
							padding: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							style={{
								width: "96%",
								textAlign: "center",
								marginTop: 10,
								backgroundColor: "#0055ff",
								borderRadius: 8,
								paddingBlock: 15,
								color: "#ffffff",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Azul
						</div>
					</Grid>
					<Grid
						item
						xs={11}
						md={9}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 37,
							marginBottom: window.innerWidth <= 960 ? 37 : 0,
							paddingLeft: 30,
							justifyContent: "center",
						}}
					>
						<Slider
							sx={style("azul").sliderHorizontal}
							aria-labelledby="azul"
							style={{ marginRight: 5, backgroundColor: "#EEEEEE" }}
							valueLabelDisplay="auto"
							min={0}
							max={1023}
							disabled={modoLeitura || this.props.carregando}
							value={this.state.azul}
							onChange={(e, val) => {
								this.setState({ ...this.state, azul: val });
							}}
							onChangeCommitted={(e, val) => this.props.salvar({ ...this.state, azul: val })}
						/>
					</Grid>
					<Grid
						item
						xs={1}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 40,
							textAlign: "center",
						}}
					>
						{Math.floor((this.state.azul * 100) / 1023) + " %"}
					</Grid>
					<Grid
						item
						xs={12}
						md={2}
						style={{
							padding: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							style={{
								width: "96%",
								textAlign: "center",
								marginTop: 10,
								backgroundColor: "#4169e1",
								borderRadius: 8,
								paddingBlock: 15,
								color: "#ffffff",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Azul Royal
						</div>
					</Grid>
					<Grid
						item
						xs={11}
						md={9}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 37,
							marginBottom: window.innerWidth <= 960 ? 37 : 0,
							paddingLeft: 30,
							justifyContent: "center",
						}}
					>
						<Slider
							sx={style("azulRoyal").sliderHorizontal}
							aria-labelledby="azulRoyal"
							style={{ marginRight: 5, backgroundColor: "#EEEEEE" }}
							valueLabelDisplay="auto"
							min={0}
							max={1023}
							disabled={modoLeitura || this.props.carregando}
							value={this.state.azulRoyal}
							onChange={(e, val) => {
								this.setState({ ...this.state, azulRoyal: val });
							}}
							onChangeCommitted={(e, val) => this.props.salvar({ ...this.state, azulRoyal: val })}
						/>
					</Grid>
					<Grid
						item
						xs={1}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 40,
							textAlign: "center",
						}}
					>
						{Math.floor((this.state.azulRoyal * 100) / 1023) + " %"}
					</Grid>
					<Grid
						item
						xs={12}
						md={2}
						style={{
							padding: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							style={{
								width: "96%",
								textAlign: "center",
								marginTop: 10,
								backgroundColor: "#E164FE",
								borderRadius: 8,
								paddingBlock: 15,
								color: "#ffffff",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							U.V.
						</div>
					</Grid>
					<Grid
						item
						xs={11}
						md={9}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 37,
							marginBottom: window.innerWidth <= 960 ? 37 : 0,
							paddingLeft: 30,
							justifyContent: "center",
						}}
					>
						<Slider
							sx={style("uv").sliderHorizontal}
							aria-labelledby="uv"
							style={{ marginRight: 5, backgroundColor: "#EEEEEE" }}
							valueLabelDisplay="auto"
							min={0}
							max={1023}
							disabled={modoLeitura || this.props.carregando}
							value={this.state.uv}
							onChange={(e, val) => {
								this.setState({ ...this.state, uv: val });
							}}
							onChangeCommitted={(e, val) => this.props.salvar({ ...this.state, uv: val })}
						/>
					</Grid>
					<Grid
						item
						xs={1}
						style={{
							paddingTop: window.innerWidth <= 960 ? 5 : 40,
							textAlign: "center",
						}}
					>
						{Math.floor((this.state.uv * 100) / 1023) + " %"}
					</Grid>
				</Grid>
				<Grid
					container
					style={{
						...style.gridButton,
						padding: 20,
						marginBottom: 100,
					}}
					direction="row"
					justifyContent="center"
				>
					<Grid item xs={12} md={4} style={{ padding: 10 }}>
						<CustomButton
							fullWidth={true}
							variant="outlined"
							color="#ff5555"
							disabled={this.desabilitarBotao()}
							onClick={() => {
								this.setState({ ...this.props.registro });
								this.props.desativarControleManual();
							}}
						>
							Desativar Controle Manual
						</CustomButton>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.controleManual.registro,
	carregando: state.controleManual.carregando,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			getDados,
			resetState,
			getListaDispositivo,
			salvar,
			desativarControleManual,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(ControleManualForm);
