import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { Button, Grid } from "@material-ui/core";
import { CustomButton } from "../common/components/CustomButton";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import { initForm, salvar, getDados } from "./dataHoraActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";

class DataHoraForm extends Component {
	async componentDidMount() {
		await this.props.getListaDispositivo(this.props.sessao.id || 0);
		this.props.getDados();
	}
	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado)) this.props.getDados();
	}
	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100,
				height: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			gridButton: {
				marginTop: 20,
			},
		};
	}

	render() {
		const style = this.getLocalStyles();
		const modoLeitura = !!(this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.id_dispositivo_pai);
		return (
			<Grid container spacing={1} style={{ ...style.root }} direction="column">
				{modoLeitura && (
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<h6>(Modo Leitura)</h6>
					</Grid>
				)}
				<Grid container direction="row" justifyContent="center" alignItems="center">
					{this.props.registro.dataHora && !modoLeitura ? (
						<MuiPickersUtilsProvider utils={LuxonUtils}>
							<KeyboardDateTimePicker
								disableToolbar
								variant="inline"
								format="dd/MM/yyyy HH:mm"
								margin="normal"
								id="date-picker-inline"
								label="Data"
								ampm={false}
								mask="__/__/____ __:__"
								value={this.props.registro.dataHora}
								onChange={(data) => {
									this.props.initForm({
										...this.props.registro,
										dataHora: data,
									});
								}}
								KeyboardButtonProps={{
									"aria-label": "Altera a data",
								}}
								sx={{
									"&.MuiButtonBase-root": {
										borderRadius: "0 !important",
										border: "0 !important",
									},
								}}
							/>
						</MuiPickersUtilsProvider>
					) : (
						<span
							style={{
								backgroundColor: "#eee",
								color: "#aaa",
								fontSize: "1.2rem",
								paddingInline: 20,
								paddingBlock: 10,
								borderRadius: 8,
								userSelect: "none",
								WebkitUserSelect: "none",
								msUserSelect: "none",
							}}
						>
							{this.props.registro.dataHora
								? new Date(this.props.registro.dataHora).toLocaleString("pt-BR", { dateStyle: "short", timeStyle: "short" })
								: "____/____/____ ___:___"}
						</span>
					)}
				</Grid>
				<Grid container style={{ ...style.gridButton, paddingBlock: 20 }} direction="row" justifyContent="center">
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={!this.props.registro.dataHora}
							onClick={() => this.props.salvar(this.props.registro)}
						>
							Salvar
						</Button>
					</Grid>
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<CustomButton color="#0066ff" fullWidth={true} variant="outlined" href="#/">
							Voltar
						</CustomButton>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

DataHoraForm = reduxForm({ form: "dataHoraForm", destroyOnUnmount: false })(DataHoraForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.dataHora.registro,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ initForm, salvar, getDados, getListaDispositivo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DataHoraForm);
