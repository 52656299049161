const INITIAL_STATE = {
	carregando: true,
	registro: {
		branco: false,
		azul: false,
		azulRoyal: false,
		uv: false,
		intervaloDias: 1,
		probabilidadeNuvem: 0,
		quantidadeMinMaxDia: [0, 0],
		duracaoMinMaxDia: [0, 0],
		probabilidadeRelampago: 0,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "RESETAR_CLIMA":
			return INITIAL_STATE;
		case "CLIMA_LISTADO":
			const registro = {
				branco: action.payload.data.nBranco,
				azul: action.payload.data.nAzul,
				azulRoyal: action.payload.data.nAzulRoyal,
				uv: action.payload.data.nUV,
				extra: action.payload.data.nExtra !== undefined ? action.payload.data.nExtra : undefined,
				intervaloDias: parseInt(action.payload.data.dias) || 1, //minimo é um, se mandar zero buga as luminárias
				probabilidadeNuvem: action.payload.data.nProbabilidade,
				probabilidadeRelampago: action.payload.data.rProbabilidade,
				quantidadeMinMaxDia: [action.payload.data.quantidadeMin, action.payload.data.quantidadeMax],
				duracaoMinMaxDia: [action.payload.data.duracaoMin, action.payload.data.duracaoMax],
			};

			return {
				...state,
				carregando: false,
				registro,
			};

		default:
			return state;
	}
};
