import React from "react";
import Grid from "../layout/grid";
import Select from "react-select";

export default (props) => (
  <Grid cols={props.cols}>
    <div className="form-group">
      <label htmlFor={props.name}>{props.label}</label>
      {props.onChange ? (
        <Select
          isDisabled={props.disabled}
          placeholder={props.placeholder}
          value={
            props.value
              ? (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .find((opt) => opt.value == props.value)
              : null
          }
          isClearable={props.clearable !== undefined ? props.clearable : true}
          isSearchable={true}
          onChange={(item) => props.onChange(item ? item.value : null)}
          options={(props.options || []).map((opt) => ({
            value: opt.id,
            label: opt.valor,
          }))}
        />
      ) : (
        <Select
          readOnly={props.readOnly}
          isClearable={props.clearable !== undefined ? props.clearable : true}
          isSearchable={true}
          value={
            props.value
              ? (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .find((opt) => opt.value == props.value)
              : null
          }
          onChange={(item) => props.input.onChange(item ? item.value : null)}
          onBlur={(value) => props.input.onBlur()}
          options={(props.options || []).map((opt) => ({
            value: opt.id,
            label: opt.valor,
          }))}
        />
      )}
    </div>
  </Grid>
);
