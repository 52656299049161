import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";
import { sleep } from "../utils/sleep";

export function setModoTela(modo) {
  return async (dispatch, getState) => {
    if (modo == "dashboard") {
      dispatch(getDados());
    }

    dispatch({
      type: "DASHBOARD_MODO_TELA",
      payload: {
        modo: modo,
      },
    });
  };
}

export function getDados() {
  return async (dispatch, getState) => {
    dispatch({
      type: "RESETAR_DASHBOARD",
    });
    if (getState().controleManual.ativado) {
      await sleep(3000);
    }

    if (getState().dispositivo.dispositivoSelecionado) {
      axios
        .get(
          `${consts.API_URL}/aquario/dashboard?serie=${
            getState().dispositivo.dispositivoSelecionado.serie
          }`
        )
        .then((resp) => {
          dispatch({
            type: "DASHBOARD_LISTADO",
            payload: resp,
          });
        })
        .catch((e) => {
          console.log(e);
          setError("Controladora não respondeu");
        });
    }
  };
  /*
	{
  "tempo": 14,
  "datahora": 1651675974,
  "datahoraCultivo": 1651640400,
  "temperatura": 23.58,
  "umidade": 0,
  "faseCultivo": 1,
  "canalUm": 1023,
  "canalDois": 1023,
  "atualizado": "14/11/2018",
  "ordemeprogresso": 0
}
*/
}
