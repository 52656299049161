import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";

export function initForm(registro = {}) {
	return {
		type: "DISPOSITIVO_USUARIO_SET_REGISTRO",
		payload: {
			registro: registro,
		},
	};
}

export function adicionar(registro) {
	return async (dispatch, getState) => {
		try {
			const resp = await axios.get(`${consts.API_URL}/dispositivo/buscarPeloSerial?serie=${registro.numero_serie}&id_tipo=1`);
			const dispositivo = resp.data || null;

			if (!dispositivo) {
				setError("Dispositivo não encontrado");
			} else if (getState().auth.usuario.administrador) {
				//verifica se o dispositivo é um escravo, se for não deixa o admin adicionar
				const dispositivoPai = (await axios.get(`${consts.API_URL}/dispositivoDependente/buscarPai?id_dispositivo_filho=${dispositivo.id}`)).data[0];
				if (dispositivoPai) {
					const paiEstaNaLista = getState().dispositivo.lista.find((item) => item.serie == dispositivoPai.serie);
					if (!paiEstaNaLista) {
						setError(`O dispositivo ${registro.numero_serie} é depende do dispositivo ${dispositivoPai.serie}`);
						return {};
					}
				}
				//Se é administrador permite criar testes
				await axios.put(`${consts.API_URL}/dispositivo`, {
					...dispositivo,
					id_usuario_administrador: getState().auth.usuario.id,
					nome_administrador: registro.nome_controladora || registro.numero_serie,
				});
				setSuccess("Operação Realizada com sucesso.");
				window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
			} else if (dispositivo.id_usuario) {
				setError("Dispositivo não está disponível");
			} else {
				await axios.put(`${consts.API_URL}/dispositivo`, {
					...dispositivo,
					id_usuario: getState().auth.usuario.id,
					nome: registro.nome_controladora || registro.numero_serie,
				});
				setSuccess("Operação Realizada com sucesso.");
				window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
			}
		} catch (e) {
			setErroAPI(e);
		}
	};
}
