import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formataHorario } from "../utils/formataHorario";
import { Button, Grid, Typography, FormControlLabel } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { CustomSwitch } from "../common/components/CustomSwitch";
import { CustomButton } from "../common/components/CustomButton";
import { setModoTela } from "../dashboard/dashboardActions";
import { getDados, salvar } from "./lunarActions";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";

class LunarForm extends Component {
	state = {
		brancoLuaNova: 0,
		brancoLuaCheia: 0,
		azulLuaNova: 0,
		azulLuaCheia: 0,
		azulRoyalLuaNova: 0,
		azulRoyalLuaCheia: 0,
		uvLuaNova: 0,
		uvLuaCheia: 0,
		desativar: false,
		horarioInicial: "00:00",
		horarioFinal: "00:00",
	};

	async componentDidMount() {
		await this.props.getListaDispositivo(this.props.sessao.id || 0);

		await this.props.getDados();
		this.setState({ ...this.props.registro });
	}

	async componentDidUpdate(prevProps) {
		if (
			JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
			prevProps.dispositivoSelecionado !== null
		) {
			await this.props.getDados();
		}

		if (JSON.stringify(prevProps.registro) !== JSON.stringify(this.props.registro)) {
			this.setState({ ...this.props.registro });
		}
	}

	getLocalStyles(cor) {
		const coresCanal = {
			branco: "#FFFFFF",
			azul: "#0055ff",
			azulRoyal: "#4169e1",
			uv: "#E164FE",
		};

		return {
			root: {
				padding: 10,
				paddingBottom: 100,
			},
			gridButton: {
				marginTop: 20,
			},
			sliderHorizontal: {
				"& .MuiSlider-thumb": {
					color: coresCanal[cor],
					height: 30,
					width: 30,
				},
				"& .MuiSlider-track": {
					color: coresCanal[cor],
					height: 15,
				},
				"& .MuiSlider-rail": {
					color: coresCanal[cor],
					height: 15,
				},
			},
		};
	}

	render() {
		const style = this.getLocalStyles;
		const modoLeitura = !!(this.props.dispositivoSelecionado && this.props.dispositivoSelecionado.id_dispositivo_pai);
		return (
			<Grid
				container
				spacing={1}
				columns={12}
				style={{
					paddingInline: 5,
					paddingInline: "5%",
				}}
			>
				<div
					justifyContent="start"
					style={{
						paddingTop: 60,
						paddingBottom: 20,
						color: "#4db6ac",
					}}
				>
					<h4>
						<i className="fa fa-moon" style={{ marginLeft: 20, marginRight: 10 }}></i>
						Lunar {modoLeitura && "(Modo Leitura)"}
					</h4>
				</div>

				<Grid
					container
					style={{
						padding: 10,
						marginBottom: 20,
						borderRadius: 10,
					}}
				>
					<Grid item xs={12} md={2} style={{ padding: 10, display: "flex", alignItems: "center" }}>
						<div
							style={{
								width: "98%",
								paddingBlock: 15,
								borderRadius: 8,
								backgroundColor: "#ffffff",
								color: "#000000",
								textAlign: "center",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Branco
						</div>
					</Grid>
					<Grid item xs={12} md={5} style={{ padding: 20 }}>
						<Typography id="branco-lua-nova" gutterBottom>
							Lua Nova
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("branco").sliderHorizontal}
								aria-labelledby="branco-lua-nova"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.brancoLuaNova}
								onChange={(e, val) => {
									this.setState({ ...this.state, brancoLuaNova: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.brancoLuaNova * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						style={{
							padding: 20,
							marginBottom: window.innerWidth <= 600 ? 37 : 0,
						}}
					>
						<Typography id="branco-lua-cheia" gutterBottom>
							Lua Cheia
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("branco").sliderHorizontal}
								aria-labelledby="branco-lua-cheia"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.brancoLuaCheia}
								onChange={(e, val) => {
									this.setState({ ...this.state, brancoLuaCheia: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.brancoLuaCheia * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid item xs={12} md={2} style={{ padding: 10, display: "flex", alignItems: "center" }}>
						<div
							style={{
								width: "98%",
								paddingBlock: 15,
								borderRadius: 8,
								backgroundColor: "#0055ff",
								color: "#ffffff",
								textAlign: "center",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Azul
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						style={{
							padding: 20,
							marginBottom: window.innerWidth <= 600 ? 37 : 0,
						}}
					>
						<Typography id="azul-lua-nova" gutterBottom>
							Lua Nova
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("azul").sliderHorizontal}
								aria-labelledby="azul-lua-nova"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.azulLuaNova}
								onChange={(e, val) => {
									this.setState({ ...this.state, azulLuaNova: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.azulLuaNova * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid item xs={12} md={5} style={{ padding: 20 }}>
						<Typography id="azul-lua-cheia" gutterBottom>
							Lua Cheia
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("azul").sliderHorizontal}
								aria-labelledby="azul-lua-cheia"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.azulLuaCheia}
								onChange={(e, val) => {
									this.setState({ ...this.state, azulLuaCheia: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.azulLuaCheia * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid item xs={12} md={2} style={{ padding: 10, display: "flex", alignItems: "center" }}>
						<div
							style={{
								width: "98%",
								paddingBlock: 15,
								borderRadius: 8,
								backgroundColor: "#4169e1",
								color: "#ffffff",
								textAlign: "center",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							Azul Royal
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						style={{
							padding: 20,
							marginBottom: window.innerWidth <= 600 ? 37 : 0,
						}}
					>
						<Typography id="azul-royal-lua-nova" gutterBottom>
							Lua Nova
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("azulRoyal").sliderHorizontal}
								aria-labelledby="azul-royal-lua-nova"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.azulRoyalLuaNova}
								onChange={(e, val) => {
									this.setState({ ...this.state, azulRoyalLuaNova: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.azulRoyalLuaNova * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						style={{
							padding: 20,
							marginBottom: window.innerWidth <= 600 ? 37 : 0,
						}}
					>
						<Typography id="azul-royal-lua-cheia" gutterBottom>
							Lua Cheia
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("azulRoyal").sliderHorizontal}
								aria-labelledby="azul-royal-lua-cheia"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.azulRoyalLuaCheia}
								onChange={(e, val) => {
									this.setState({ ...this.state, azulRoyalLuaCheia: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.azulRoyalLuaCheia * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid item xs={12} md={2} style={{ padding: 10, display: "flex", alignItems: "center" }}>
						<div
							style={{
								width: "98%",
								paddingBlock: 15,
								borderRadius: 8,
								backgroundColor: "#E164FE",
								color: "#ffffff",
								textAlign: "center",
								boxShadow: "#777777 1px 2px 5px",
							}}
						>
							U.V.
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						style={{
							padding: 20,
							marginBottom: window.innerWidth <= 600 ? 37 : 0,
						}}
					>
						<Typography id="uv-lua-nova" gutterBottom>
							Lua Nova
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("uv").sliderHorizontal}
								aria-labelledby="uv-lua-nova"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.uvLuaNova}
								onChange={(e, val) => {
									this.setState({ ...this.state, uvLuaNova: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.uvLuaNova * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						style={{
							padding: 20,
							marginBottom: window.innerWidth <= 600 ? 37 : 0,
						}}
					>
						<Typography id="uv-lua-cheia" gutterBottom>
							Lua Cheia
						</Typography>
						<span
							style={{
								marginInline: 10,
								display: "flex",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							<Slider
								sx={style("uv").sliderHorizontal}
								aria-labelledby="uv-lua-cheia"
								style={{ marginRight: 35, backgroundColor: "#EEEEEE" }}
								valueLabelDisplay="auto"
								min={0}
								max={1023}
								disabled={modoLeitura}
								value={this.state.uvLuaCheia}
								onChange={(e, val) => {
									this.setState({ ...this.state, uvLuaCheia: val });
								}}
							/>
							<span style={{ minWidth: 50 }}>{((this.state.uvLuaCheia * 100) / 1023).toFixed(0) + " %"}</span>
						</span>
					</Grid>
				</Grid>

				<Grid container spacing={1} columns={12} style={{ paddingLeft: 20 }}>
					<Grid item xs={12}>
						<FormControlLabel
							required
							control={
								<CustomSwitch
									checked={this.state.desativar}
									disabled={modoLeitura}
									onChange={(e) =>
										this.setState({
											...this.state,
											desativar: e.target.checked,
										})
									}
								/>
							}
							label={this.state.desativar ? "Desativar o efeito entre:" : "Não desativar o efeito"}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<LabelAndInputDateTime
							label="Horário Inicial"
							mask="99:99"
							placeholder="00:00"
							value={this.state.horarioInicial}
							onChange={(e) => {
								const horario = formataHorario(e.target.value);
								this.setState({ ...this.state, horarioInicial: horario });
							}}
							readOnly={!this.state.desativar || modoLeitura}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<LabelAndInputDateTime
							label="Horário Final"
							mask="99:99"
							placeholder="00:00"
							value={this.state.horarioFinal}
							onChange={(e) => {
								const horario = formataHorario(e.target.value);
								this.setState({ ...this.state, horarioFinal: horario });
							}}
							readOnly={!this.state.desativar || modoLeitura}
						/>
					</Grid>
				</Grid>
				<Grid container style={{ ...style.gridButton, paddingBlock: 20, marginBottom: 100 }} direction="row" justifyContent="center">
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={this.props.carregando || modoLeitura}
							onClick={() => {
								this.props.salvar(this.state);
							}}
						>
							Salvar
						</Button>
					</Grid>
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<CustomButton color="#0066ff" fullWidth={true} variant="outlined" href="#/">
							Voltar
						</CustomButton>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.lunar.registro,
	carregando: state.lunar.carregando,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, getDados, getListaDispositivo, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LunarForm);
