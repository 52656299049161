import * as React from "react";
// import { grey } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";

const CustomRadio = styled(Radio)(({ customColor = "#ffffff" }) => ({
  color: customColor,
  "&.Mui-checked": {
    color: customColor,
  },
}));
export default CustomRadio;
