import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";

import { setModoTela } from "./usuarioActions";

class UsuarioAlterarSenhaForm extends Component {
	render() {
		let readOnly = this.props.excluir ? "readOnly" : "";

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Field
							name="senha"
							component={LabelAndInput}
							type="password"
							label="Nova Senha *"
							placeholder="Informe a Senha"
							cols="12 12 12 12"
							readOnly={readOnly}
						/>

						<Field
							name="senha_confirmacao"
							component={LabelAndInput}
							type="password"
							label="Confirme sua Senha *"
							placeholder="Confirme sua Senha"
							cols="12 12 12 12"
							readOnly={readOnly}
						/>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Button text="Alterar" submit type={"success"} icon={"fa fa-check"} />
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

UsuarioAlterarSenhaForm = reduxForm({ form: "usuarioForm", destroyOnUnmount: false })(UsuarioAlterarSenhaForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.usuario.registro,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAlterarSenhaForm);
