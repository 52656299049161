import React from "react";

export default (props) => (
  <th
    className={props.acoes ? "table-actions" : ""}
    style={{
      textAlign: props.alignRight
        ? "right"
        : props.alignCenter
        ? "center"
        : "left",
      width: props.width,
      height: props.height,
      padding: props.padding,
      paddingLeft: props.padding || 2,
    }}
  >
    {props.children}
  </th>
);
